.all-platform-graph-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding: 1rem;

  .line-chart-container {
    canvas {
      height: 260px !important;
    }
  }
}

.nodata {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: -30px;
  justify-content: center;
  align-items: center;
}
