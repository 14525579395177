.tms-social-login {
  background-color: #fff;
  padding-top: 4.5em;
  padding-bottom: 3.2em;
  padding-left: 8.4em;
  padding-right: 8.4em;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  max-height: 100%;
  width: 59.375rem;

  .pixis-logo {
    position: absolute;
    top: 2.4em;
    left: 2.4em;
  }

  p {
    margin: 0;
  }

  .content-header {
    font-style: normal;
    font-weight: 500;
    font-size: 3.4em;
    line-height: 1.21em;
  }

  .content-msg {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6em;
    line-height: 1.4375em;
    text-align: center;
    margin-top: 1em;
  }

  .content-brand {
    font-style: normal;
    font-weight: 400;
    font-size: 2em;
    line-height: 1.2em;
    margin-top: 2.4em;

    span {
      font-weight: 800;
    }
  }

  .content-options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 3em;
    gap: 2.5rem;
    position: relative;
    flex-wrap: wrap;

    .content-option {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      min-width: 28.4em;
      gap: 0.6em;

      a {
        width: 100%;
        background: #0869fb;
        border-radius: 0.5em;
        color: white;
        cursor: pointer;
        height: 2.5em;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-weight: 500;
        font-size: 1.6em;

        margin-top: 0.5rem;
      }

      .option-logo {
        width: 7.6em;
        height: 7.6em;
        box-shadow: 0rem 0.0625rem 0.75rem rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        svg {
          width: 4.4rem;
          height: 4.4rem;
        }

        &.google {
          svg {
            width: 5.4em;
          }
        }
        &.facebook {
          svg {
            width: 2.4em;
          }
        }
        &.linkedin {
          svg {
            width: 4.4em;
          }
        }
        &.pinterest {
          svg {
            width: 5em;
          }
        }
      }

      .option-title {
        font-style: normal;
        font-weight: 600;
        font-size: 1.8em;
        line-height: 1.33em;
        color: #333333;
        text-transform: capitalize;
      }

      .option-message {
        font-style: normal;
        font-weight: 400;
        font-size: 1.6em;
        line-height: 1.1875em;
        text-align: center;
        color: #a1a1a1;
      }
    }
  }

  .skip-link {
    font-style: normal;
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.25em;
    text-decoration: underline !important;
    color: #0869fb !important;
    margin-top: 6.33333em;
    cursor: pointer;
  }
}

.tms-loading {
  font-size: 1.5rem;
}
