.audience-manager {
  .font-inter {
    * {
      font-family: "Inter" !important;
    }
  }
  .font-lato {
    font-family: "Lato" !important;
  }

  /* font size */
  .font-9 {
    font-size: 0.563rem;
  }

  .font-10 {
    font-size: 0.625rem !important;
  }

  .font-1 {
    font-size: 1rem !important;
  }

  .font-11 {
    font-size: 0.688rem;
  }

  .font-12 {
    font-size: 0.75rem;
  }

  .font-13 {
    font-size: 0.813rem;
  }

  .font-14 {
    font-size: 0.875rem;
  }

  .font-15 {
    font-size: 0.938rem;
  }

  .font-16 {
    font-size: 1rem;
  }

  .font-17 {
    font-size: 1.063rem;
  }

  .font-18 {
    font-size: 1.125rem;
  }
  .font-22 {
    font-size: 1.375rem;
  }
  .font-36 {
    font-size: 2.25rem;
  }

  /* font weight */
  .font-400 {
    font-weight: 400;
  }

  .font-500 {
    font-weight: 500;
  }

  .font-600 {
    font-weight: 600 !important;
  }

  .font-700 {
    font-weight: 700;
  }

  .font-800 {
    font-weight: 800;
  }

  .overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .audience-secondary-tiles {
    h5 {
      font-size: 1rem !important;
    }
    h6 {
      font-size: 0.75rem !important;
    }
    .MuiCard-root {
      border-radius: 0.5rem;
      border-color: #ddd;
      margin: 0 !important;
    }
  }
  .audience-secondary-tile-selected {
    border-color: #0869fb !important;
  }
}
