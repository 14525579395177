@import "src/scss/variables.scss";

.variant-drawer {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: calc(100vw - 64px);
  height: 100%;
  transform: translate(300px, 0);
  will-change: transform, visibility;
  background-color: white;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 1031;
}

.variant-drawer[aria-hidden="false"] {
  transform: translate(0, 0);
  transition: transform 0.3s linear;
}

.variant-drawer[aria-hidden="true"] {
  transition: visibility 0s linear 0.3s, transform 0.3s linear;
}

.variant-drawer-container {
  display: flex;
}

.variant-drawer-container--close-container {
  width: $drawer-close-container;
  height: 100vh;
}

.variant-drawer-container--close {
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}

.variant-drawer-container--body {
  width: 100%;
  padding: 1.5rem 1.5rem 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #f5f5f5;
}

@media only screen and (min-width: 900px) and (max-width: 1600px) {
  .variant-drawer-container--close-container {
    width: 3rem;
  }
  .variant-drawer-container--body {
    padding: 1rem 1rem 0px;
  }
}

.createad-variant-drawer-body {
  display: flex;
  flex-grow: 1;
  max-height: calc(100% - 6%);
  .drawer-panel {
    border: 0.0625rem solid #dadce0;
    box-sizing: border-box;
    box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.08);
    border-radius: 0.3125rem;
    width: 73%;
    padding: 1.5rem;
    overflow: scroll;
    background: white;
    border-radius: 0.625rem;
  }
  .drawer-right-panel {
    padding: 1.2rem;
    margin-left: 1.2rem;
    background: #f5faff;
    width: 27%;
    padding-bottom: 0.5rem;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1600px) {
  .createad-variant-drawer-body {
    max-height: calc(100% - 8%);
    .drawer-panel {
      padding: 0.5rem 1.2rem;
    }
  }
}
