.audience-container {
  border-radius: 8px;
  .cohort-details {
    // width: 100%;
    width: 22rem;
    // padding: 5px;
    background: #ffffff;
    border: 1px solid #efefef;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin: 5px;
    margin-bottom: 10px !important;
    .cohort {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #efefef;
      div {
        span {
          font-size: 14px;
        }
        input {
          &[type="checkbox"] {
            display: inline;
          }
        }
      }
    }
    .interests-container {
      .tags {
        div {
          .cohort-keys {
            background: #F5F5F5;
            border-radius: 0.375rem;
            padding: 0.313rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 0.75rem;
            max-width: 5rem;
            width: 5rem;
          }
          .view-more-section {
            min-width: 25%;
            width: 25%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            button {
              padding: 0.625rem 0 !important;
            }
          }
        }
      }
    }
  }
  .normal {
    path {
      fill: #fff;
    }
  }
  .filled {
    path {
      fill: #0869fb;
    }
  }
}
