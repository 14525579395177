.phase-timeline {
  position: absolute;
  top: 48px;
  padding: 15px 15px 0px;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 2;
  right: -1%;
  background: white;
  max-height: 76vh;
  overflow: scroll;
}
.phase-timeline--container {
  padding: 10px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  margin-bottom: 15px;
}
.phase-timeline--title {
  font-family: Roboto;
  font-size: 15px;
  line-height: 19px;
  color: #000000;
  padding-bottom: 8px;
  text-align: left;
  text-transform: capitalize;
}
.phase-timeline--date {
  font-family: Roboto;
  font-size: 13px;
  line-height: 16px;
  color: #333333;
  padding-bottom: 12px;
  display: flex;
  .phase-date {
    white-space: pre;
    color: #70a3fd;
  }
}
.phase-timeline--no-data{
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  display: flex;
  margin-bottom: 15px;
}
.phase-timeline--status {
  padding: 8px 10px;
  border-radius: 71px;
  font-family: Roboto;
  font-size: 13px;
  line-height: 16px;
  width: fit-content;
  background: #fff8e0;
  color: #be9709;
  margin-bottom: 8px;
}
.status__running {
  background: #e0e7ff;
  color: #0869fb;
}
.status__complete {
  color: #64be09;
  background: #f0ffe0;
}
