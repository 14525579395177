.action_debugger_chart {
  width: 100%;
}

.layout {
  position: relative;
  overflow: hidden;
}

.inner_layout {
  position: relative;
  padding-top: 1.2em;
}

.chart_layout {
  display: flex;
  align-items: center;
  padding-bottom: 0.7em;
  width: 100%;
}

.chart_container {
  width: 85%;
  margin-right: 0.7em;
  padding-right: 0.4em;
  padding-left: 0.7em;
}

.chart_select {
  min-width: 15%;
  max-width: 15%;
  // padding-right: 0.7em;
}

.layout .multi-range-slider {
  position: absolute;
  left: calc(16% + 35px);
  right: 1.5vw;
  top: 2.97em;
  bottom: 0;
  height: calc(100% - 40px);
  z-index: 1;
  pointer-events: none;
  border-radius: 0;
  box-shadow: none;
  border-radius: 0;
  border: none;
  padding: 0;
}

.layout .multi-range-slider .bar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.layout .multi-range-slider .bar-left {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: none;
}

.layout .multi-range-slider .bar-right {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: none;
}

.layout .multi-range-slider .bar-inner {
  background: none;
  box-shadow: none;
  border: none;
}

.layout .multi-range-slider .thumb {
  top: 0%;
  pointer-events: all;
}

.layout .multi-range-slider .thumb::before {
  width: 24px;
  height: 24px;
  border-radius: 5px;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiByeD0iNCIgZmlsbD0iI0U2RjBGRiIvPgo8cmVjdCB4PSIzLjAwMzkxIiB5PSI3IiB3aWR0aD0iMTcuOTI3NSIgaGVpZ2h0PSI0IiByeD0iMiIgZmlsbD0iIzA4NjlGQiIvPgo8cGF0aCBkPSJNMy4wMDM5MSAxNUMzLjAwMzkxIDEzLjg5NTQgMy44OTkzNCAxMyA1LjAwMzkxIDEzSDE4LjkzMTRDMjAuMDM2IDEzIDIwLjkzMTQgMTMuODk1NCAyMC45MzE0IDE1QzIwLjkzMTQgMTYuMTA0NiAyMC4wMzYgMTcgMTguOTMxNCAxN0g1LjAwMzlDMy44OTkzMyAxNyAzLjAwMzkxIDE2LjEwNDYgMy4wMDM5MSAxNVoiIGZpbGw9IiMwODY5RkIiLz4KPC9zdmc+Cg==)
    no-repeat 0 0;
  background-size: contain;
  pointer-events: all;
  border-radius: 0;
  box-shadow: none;
  border: none;
  width: 50px;
  background-size: 24px 24px;
  height: 100%;
}

.layout .multi-range-slider .thumb {
  background: none;
  width: 0px;
  background: rgba(255, 255, 255, 0.7);
}

.layout .thumb.thumb-left,
.layout .thumb.thumb-right {
  background-image: linear-gradient(
    to bottom,
    #3f52ff 50%,
    rgba(255, 255, 255, 0) 30%
  );
  background-position: left;
  background-size: 1px 9px;
  width: 0.5px;
  background-repeat: repeat-y;
}

.layout .multi-range-slider .thumb::before {
  margin: 0;
  top: 0%;
}

.layout .thumb.thumb-left::before {
  right: -12px;
  background-position: right center;
}

.layout .thumb.thumb-right::before {
  left: -12px;
  background-position: 0px center;
}

.layout .multi-range-slider .thumb * {
  display: none !important;
}

.highcharts-container {
  width: 100% !important;
}

.highcharts-tooltip-header {
  display: none;
}

.first-title {
  position: relative;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 0.875em;
  line-height: 1em;
  color: #333333;
  margin: 0.6em 0.2em;
}

.phase-wrapper {
  color: #111111;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75em;
  line-height: 0.875em;
  margin: 0.6em 0.2em;
}

.second-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1em;
  color: #333333;
  margin: 0.6em 0.2em;
}

/*Tooltip text*/
.customised-tooltip {
  position: relative;
}

.customised-tooltip .tooltip-text {
  visibility: hidden;
  width: 13.5625em;
  background-color: #e4ebfa;
  color: #333333;
  border-radius: 0.375em;
  padding: 0.625em;
  position: absolute;
  z-index: 999999;
  bottom: 125%;
  left: 100%;
  opacity: 0;
  transition: 0.3s;
  transform: translate3d(0px, 1.25em, 0px);
}

.customised-tooltip .tooltip-text .title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1.5em;
}

.customised-tooltip .tooltip-text .body {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75em;
  line-height: 0.9375em;
}

.customised-tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}

.metrics-name-header {
  left: 0.5em;
  font-family: "Lato";
  font-weight: 600;
  font-size: 1.125em;
  line-height: 1.375em;
  position: absolute;
}

.chart_select .select-dropdown {
  width: 16rem !important;
}

.divider-chart {
  background: #ffffff;
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: #ecebf0;
  border-radius: 0.25em;
  margin: 0em 1em;
}
