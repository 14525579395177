.tai-style {
  .react-flow__node-input {
    font-size: 1rem !important;
    font-weight: bold;
  }
  /*
.dropdown {
  position: absolute;
  inset: 0 auto auto 0;
  margin: 0;
  transform: translate(295px, 70px);
}
*/
  .react-flow__handle {
    height: 0 !important;
    width: 0 !important;
  }

  .source.react-flow__handle-right {
    height: 40px !important;
    width: 25px !important;
    background: #0869fb !important;
    border-radius: 0 150px 150px 0 !important;
    right: -25px !important;
  }

  .target.react-flow__handle-left {
    background: #0869fb !important;
    height: 15px !important;
    width: 15px !important;
    left: -5px !important;
  }

  .target.react-flow__handle-right {
    background: transparent !important;
    height: 15px !important;
    width: 7.5px !important;
    right: 0 !important;
    border-radius: 150px 0 0  150px !important;
    border-color: transparent !important; 
  }

  .loaderCollapse {
    .react-flow__handle-right {
      width: 0 !important;
      height: 0 !important;
      right: 0 !important;
    }
    .react-flow__handle-left {
      background: transparent !important;
      border: none !important;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
  }
  .toggle-switch {
    position: relative;
    width: 75px;
    display: inline-block;
    text-align: left;
  }
  .checkbox {
    display: none;
  }
  .label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
  }
  .inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }
  .inner:before,
  .inner:after {
    float: left;
    width: 50%;
    height: 26px;
    padding: 0;
    line-height: 36px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
  }
  .inner:before {
    content: "";
    padding-left: 10px;
    background-color: blue;
    color: #fff;
  }
  .inner:after {
    content: "";
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
  }
  .switch {
    display: block;
    width: 24px;
    margin: 2px;
    background: blue;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid #bbb;
    border-radius: 10px;
    transition: all 0.3s ease-in 0s;
  }
  .checkbox:checked + .label .inner {
    margin-left: 0;
  }
  .checkbox:checked + .label .switch {
    right: 0px;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 5px gainsboro;
    background: #60c7bd; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #5d7cca,
      #60c7bd
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #5d7cca,
      #60c7bd
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    height: 7.5px;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-moz-range-track {
    -moz-appearance: none;
    background: rgba(59, 173, 227, 1);
    background: -moz-linear-gradient(
      45deg,
      rgba(59, 173, 227, 1) 0%,
      rgba(87, 111, 230, 1) 25%,
      rgba(152, 68, 183, 1) 51%,
      rgba(255, 53, 127, 1) 100%
    );
    background: -webkit-gradient(
      left bottom,
      right top,
      color-stop(0%, rgba(59, 173, 227, 1)),
      color-stop(25%, rgba(87, 111, 230, 1)),
      color-stop(51%, rgba(152, 68, 183, 1)),
      color-stop(100%, rgba(255, 53, 127, 1))
    );
    background: -webkit-linear-gradient(
      45deg,
      rgba(59, 173, 227, 1) 0%,
      rgba(87, 111, 230, 1) 25%,
      rgba(152, 68, 183, 1) 51%,
      rgba(255, 53, 127, 1) 100%
    );
    background: -o-linear-gradient(
      45deg,
      rgba(59, 173, 227, 1) 0%,
      rgba(87, 111, 230, 1) 25%,
      rgba(152, 68, 183, 1) 51%,
      rgba(255, 53, 127, 1) 100%
    );
    background: -ms-linear-gradient(
      45deg,
      rgba(59, 173, 227, 1) 0%,
      rgba(87, 111, 230, 1) 25%,
      rgba(152, 68, 183, 1) 51%,
      rgba(255, 53, 127, 1) 100%
    );
    background: linear-gradient(
      45deg,
      rgba(59, 173, 227, 1) 0%,
      rgba(87, 111, 230, 1) 25%,
      rgba(152, 68, 183, 1) 51%,
      rgba(255, 53, 127, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3bade3 ', endColorstr='#ff357f ', GradientType=1 );
    height: 2px;
  }
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 2px solid;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    max-width: 80px;
    position: relative;
    bottom: 5px;
    background-color: transparent;
    cursor: -webkit-grab;
    -webkit-transition: border 1000ms ease;
    transition: border 1000ms ease;
  }
  input[type="range"]::-moz-range-thumb {
    -moz-appearance: none;
    border: 2px solid;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    max-width: 80px;
    position: relative;
    bottom: 11px;
    background-color: transparent;
    cursor: -moz-grab;
    -moz-transition: border 1000ms ease;
    transition: border 1000ms ease;
    display: flex;
    justify-content: space-between;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 5px gainsboro;
    background: #60c7bd; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #5d7cca,
      #60c7bd
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #5d7cca,
      #60c7bd
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    height: 7.5px;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-moz-range-track {
    -moz-appearance: none;
    background: rgba(59, 173, 227, 1);
    background: -moz-linear-gradient(
      45deg,
      rgba(59, 173, 227, 1) 0%,
      rgba(87, 111, 230, 1) 25%,
      rgba(152, 68, 183, 1) 51%,
      rgba(255, 53, 127, 1) 100%
    );
    background: -webkit-gradient(
      left bottom,
      right top,
      color-stop(0%, rgba(59, 173, 227, 1)),
      color-stop(25%, rgba(87, 111, 230, 1)),
      color-stop(51%, rgba(152, 68, 183, 1)),
      color-stop(100%, rgba(255, 53, 127, 1))
    );
    background: -webkit-linear-gradient(
      45deg,
      rgba(59, 173, 227, 1) 0%,
      rgba(87, 111, 230, 1) 25%,
      rgba(152, 68, 183, 1) 51%,
      rgba(255, 53, 127, 1) 100%
    );
    background: -o-linear-gradient(
      45deg,
      rgba(59, 173, 227, 1) 0%,
      rgba(87, 111, 230, 1) 25%,
      rgba(152, 68, 183, 1) 51%,
      rgba(255, 53, 127, 1) 100%
    );
    background: -ms-linear-gradient(
      45deg,
      rgba(59, 173, 227, 1) 0%,
      rgba(87, 111, 230, 1) 25%,
      rgba(152, 68, 183, 1) 51%,
      rgba(255, 53, 127, 1) 100%
    );
    background: linear-gradient(
      45deg,
      rgba(59, 173, 227, 1) 0%,
      rgba(87, 111, 230, 1) 25%,
      rgba(152, 68, 183, 1) 51%,
      rgba(255, 53, 127, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3bade3 ', endColorstr='#ff357f ', GradientType=1 );
    height: 2px;
  }
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 2px solid;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    max-width: 80px;
    position: relative;
    bottom: 5px;
    background-color: transparent;
    cursor: -webkit-grab;
    -webkit-transition: border 1000ms ease;
    transition: border 1000ms ease;
  }
  input[type="range"]::-moz-range-thumb {
    -moz-appearance: none;
    border: 2px solid;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    max-width: 80px;
    position: relative;
    bottom: 11px;
    background-color: transparent;
    cursor: -moz-grab;
    -moz-transition: border 1000ms ease;
    transition: border 1000ms ease;
  }

  .main {
  }

  .circle {
    width: 100%;
    margin-left: 0.25rem;
    float: left;
    color: #0769fb;
    text-align: -webkit-center;
  }

  .main .circle .step:after {
    content: "";
    display: block;
    position: relative;
    top: 0.313rem;
    left: 50%;
    width: 100%;
    border-left: 1px dashed #a3a3a3;
    height: 3.125rem;
  }

  .firstNode {
    width: 24%;
  }

  .otherNodes {
    width: 100%;
  }

  .react-flow__attribution {
    display: none !important;
  }

  .custom-react-toggle .react-toggle-track {
    /* width: 38px;
    height: 12px; */
    width: 24px;
    height: 14px;
    background-color: #e2e2e2 !important;
  }
  /* .custom-react-toggle .react-toggle-track :hover {
  background-color: #caddff;
}
.custom-react-toggle.react-toggle-track-check :hover {
  background-color: #caddff;
} */

  .custom-react-toggle .react-toggle-thumb {
    /* width: 16px;
    height: 16px;
    top: -2px; */
    width: 16px;
    height: 16px;
    top: -1px;
    background-color: #777777;
    border-color: #777777;
  }
  .custom-react-toggle.react-toggle--checked .react-toggle-thumb {
    left: 12px !important;
    /* border-color: #19ab27; */
    border-color: #0869fb;
    background-color: #0869fb;
  }
  /* .custom-react-toggle.react-toggle-track-check :hover {
    background-color: #caddff;
} */

  .custom-react-toggle.react-toggle--checked .react-toggle-track {
    background-color: #caddff !important;
  }

  .react-flow__attribution {
    display: none !important;
  }
  .edgebutton {
    width: 20px;
    height: 20px;
    background: #eee;
    border: 1px solid #fff;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    line-height: 1;
  }

  .edgebutton:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
  }

  .edgebutton-foreignobject body {
    background: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
  }

  .card-style {
    border: 1px solid #efefef;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
  #playground-intro {
    width: 35%;
  }

  #tai-sidebar {
    height: 100%;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #nodes {
    width: 100%;
  }
  .disabled-ui {
    opacity: 0.5;
  }
  .tai-font {
    font-family: "Lato", sans-serif;
  }
  .tai-heading {
    font-weight: 700 !important;
    font-size: 2.75rem !important;
    font-weight: 700 !important;
    line-height: 3rem;
    /* font-size: 3.038rem !important;
  line-height: 3.589rem; */
  }

  .basicHeaderCollapsed {
    background: #8080801f;
    padding: 12px;
    .content {
      .white-div {
        background: white;
        padding: 5px;
        border-radius: 10px;
        font-size: 15px;
        text-align: center;
      }
      .grey-div {
        background: #80808047;
        padding: 5px;
        border-radius: 10px;
        font-size: 15px;
        text-align: center;
      }
    }
  }

  .keywordCollapsed {
    background: #8080801c;
    width: 200px;
    .content {
      padding: 10px;
    }
  }
}

.font-inter {
  * {
    font-family: "Inter" !important;
  }
}
.font-lato {
  font-family: "Lato" !important;
}
.font-8 {
  font-size: 0.5rem !important;
}
.font-10 {
  font-size: 0.625rem !important;
}
.font-12 {
  font-size: 0.75rem !important;
}
.font-14 {
  font-size: 0.875rem !important;
}
.font-16 {
  font-size: 1rem !important;
}
.font-18 {
  font-size: 1.125rem !important;
}
.font-20 {
  font-size: 1.25rem !important;
}
.font-30 {
  font-size: 1.87rem !important;
}
.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-700 {
  font-weight: 700 !important;
}

.font-inter {
  * {
    font-family: "Inter" !important;
  }
}

.custom-react-toggle .react-toggle-track {
  // width: 24px;
  // height: 14px;
  width: 20px;
  height: 10px;
  background-color: #e2e2e2 !important;
}

.custom-react-toggle .react-toggle-thumb {
  // width: 16px;
  // height: 16px;
  width: 12px;
  height: 12px;
  top: -1px;
  background-color: #777777;
  border-color: #777777;
}
.custom-react-toggle.react-toggle--checked .react-toggle-thumb {
  left: 12px !important;
  border-color: #0869fb;
  background-color: #0869fb;
}

.custom-react-toggle.react-toggle--checked .react-toggle-track {
  background-color: #caddff !important;
}
.custom-slider {
  .MuiSlider-markLabel::after {
    content: " Days";
  }
}

.node-container-common-style {
  border: 0.047rem solid #efefef;
  box-shadow: 0 0.187rem 0.656rem rgba(0, 0, 0, 0.05);
  border-radius: 0.468rem;
}

.adset-name-section {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
}
.border-separator {
  border-bottom: 1px solid #EFEFEF;
}
