.keyword-cluster {
  .cell {
    .action-float {
      right: 10px;
      top: 50%;
      transform: translate(0, -50%);
      width: auto;
      display: none;
      cursor: pointer;
    }
    &:hover {
      .action-float {
        display: block;
      }
    }
  }
  .action {
    &.active {
      transform: rotate(180deg);
    }
  }
  .cluster-name {
    .form-control {
      line-height: 16px;
      padding: 0.7rem 0.7rem;
      border-radius: 8px;
    }
    .show.dropdown {
      margin-bottom: 7rem;
    }
  }
}
