.target-keyword {
  .target-keyword-title {
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    padding-bottom: 15px;
  }
  .target-input {
    padding: 16px;
    margin-bottom: 30px;
  }
  .target-radio-input {
    background: #f5f6f7;
    border-radius: 4px;
    padding: 10px;
    font-family: Roboto;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    width: 500px;
  }
  .generation-container {
    display: grid;
    margin-bottom: 30px;
  }
}
