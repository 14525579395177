.createAd-drawer-footer {
  background: #ffffff;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 10px 10px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 12px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  .footer-btn {
    border-radius: 12px;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 24px;
  }
}
.create-ad-drawer-error {
  color: rgb(204, 4, 4);
}
.createAd-footer-error {
  justify-content: space-between;
}
