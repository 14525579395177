.non-selected-label {
  font-family: "Lato";
  font-style: "normal";
  font-weight: "600";
  font-size: "1em";
  line-height: "1.1875em";
  color: #0869fb;
  top: "0px";
}

.selected-label {
  // @extend .non-selected-label;
  background: rgba(8, 105, 251, 0.1);
  // border-radius: 4px;
  padding: 0.2em 0.2em;
  font-family: "Lato";
  font-style: "normal";
  font-weight: "600";
  font-size: "1em";
  line-height: "1.1875em";
  color: #0869fb;
  top: "0px";
}

.custom-component-chart {
  overflow: visible !important;
  // font-size: 0.82vw !important;
  // @media screen and (max-width: 1280px) {
  //   font-size: 10px !important;
  // }

  .highcharts-tooltip {
    // background-color: #e4ebfa;
    // border-radius: 0.625em;
    z-index: 9999 !important;
    font-size: 0.82vw !important;
    // box-shadow: 1px 1px 2px #888;
    // padding: 0.8em 1em 0em 1em;
    @media screen and (max-width: 1280px) {
      font-size: 10px !important;
    }
    span {
      padding: 0em;
      box-shadow: none;
    }

    // .color-box {
    //   margin-right: 0.1em;
    //   // font-size: 8em;
    //   // vertical-align: middle;
    //   width: 0.5em !important;
    //   height: 0.5em !important;
    //   border-radius: 0 !important;
    //   // background-color: red;
    // }
    .first-title {
      position: relative;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 0.875em;
      line-height: 1em;
      color: #333333;
      margin: 0.6em 0.2em;
    }

    .phase-wrapper {
      color: #111111;
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 0.75em;
      line-height: 0.875em;
      margin: 0.6em 0.2em;
    }

    .second-title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 0.75em;
      line-height: 1em;
      color: #333333;
      // margin: 0.6em 0.2em;
    }
  }
}
