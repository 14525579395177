.warning-message-container {
    .bg-red {
        background: rgba(232, 75, 71, 0.1);
        border-radius: 0.3rem;

        &.boreder-present {
            border: 1px solid #E84B47;
        }

        .color-text {
            color: #E84B47;
        }

        .color-fill {
            path {
                fill: #E84B47
            }
        }
    }

    .bg-yellow {
        background: rgba(245, 165, 36, 0.1);
        border-radius: 0.3rem;

        &.boreder-present {
            border: 1px solid #F5A623;
        }

        .color-text {
            color: #F5A623;
        }

        .color-fill {
            path {
                fill: #F5A623
            }
        }
    }

    .close-icon {
        width: 11px;
        cursor: pointer;

        path {
            fill: #000;
        }
    }

    .bg-grey {
        background-color: #F5F5F5;
    }
}