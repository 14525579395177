.tai-style{

.button-styling {
  background-color: transparent;
  padding: 3px 10px;
  color: #0869fb;
  border: 1px solid #0869fb;
  border-radius: 8px;
  font-size: 10px !important;
}

.chip-custom-style {
  padding: 0 !important;
  margin: 0 5px !important;
  font-size: 10px !important;
}

.chip-remove-custom-style {
  margin: 0 5px !important;
  padding: 0 !important;
  font-size: 8px !important;
  border: none;
  background: transparent;
  margin-right: 5px !important;
}
}