.searchable-select-accordian {
  * {
    font-family: "Inter" !important;
    font-size: 0.875rem !important;
    color: "#1F2127";
  }
  .expand-more {
    font-size: 1.25rem !important;
  }
}
