.radio {
  .radio-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
  }
  .radio-label {
    margin-bottom: 0 !important;
    margin-left: 7px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 26rem;
    .radio-popup {
      display: none;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      padding: 12px;
      background: white;
      position: absolute;
      width: 100%;
      color: #666666;
      z-index: 9;
      white-space: break-spaces;
      bottom: 60px;
    }
    &:hover .radio-popup {
      display: block;
    }
  }
  .radio-label-container {
    display: inline-flex;
    align-items: flex-end;
    align-items: center;
  }
  .radio-heading {
    margin-left: 0.5rem;
    color: #3975ea;
  }
  .radio-description {
    margin-left: 0.5rem;
  }
}
