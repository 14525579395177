.button-styling {
  background-color: transparent;
  padding: 3px 10px;
  color: #0869fb;
  border: 1px solid #0869fb;
  border-radius: 8px;
  font-size: 10px !important;
}

.kw-chip-custom-style {
  margin: 0 !important;
  font-size: 12px !important;
  padding: 0.375rem 0px 0.375rem 0.625rem !important;
}

.chip-remove-custom-style {
  margin: 0 5px !important;
  padding: 0 !important;
  font-size: 8px !important;
  border: none;
  background: transparent;
  margin-right: 5px !important;
}

.add-btn {
  background: #0869fb;
  height: 2rem;
  width: 2rem;
  border-radius: 100%;
  margin-right: 15px;
  color: #fff;
  font-size: xx-large;
  position: relative;
}

.add-span-sym {
  left: 50%;
}

.keyword-collapsed {
  min-width: 260px;
  background: #ffffff;
  border-radius: 0.625rem;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  padding: 0.75rem;
  display: flex;

  flex-direction: column;
  * {
    font-family: "Inter";
  }
  .collapsed-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 1rem;
    .header-icon {
      margin-left: auto;
    }
    .collapsed-heading {
      display: flex;
      align-items: center;
    }
  }
  .collapsed-content {
    margin-top: 0.5rem;
    display: flex;
    width: 100%;
    border-radius: 0.625rem;
    border: 1px solid #00000030;
    padding: 0.5rem;
    .collapsed-label {
      font-weight: 400;
      font-family: "Lato";
      font-size: 0.825rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .collapsed-data {
      margin-left: auto;
      display: flex;
      justify-content: space-between;
      opacity: 1;
      .collapsed-show {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .collapsed-length {
        display: flex;
        flex-direction: column;
        font-weight: 800;
        margin-right: 0.25rem;
        justify-content: center;
        align-items: center;
        line-height: 1;
        .collapsed-keywords {
          margin-top: 0px;
          font-size: 0.5rem !important;
        }
      }
    }
  }
}

.popover-keyword-setup {
  border: 1px solid #5e9efe !important;
  box-shadow: 0px 8px 12px rgba(0, 31, 79, 0.08);
  min-width: 20rem;
  .popover-arrow::before {
    border-right-color: #5e9efe;
  }
  .custom-popover-header {
    font-family: "Inter";
    font-weight: 600;
    background: white;
    border: 0px;
    padding: 0.75rem 1rem;
    display: flex;
    .popover-header-close {
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.7;
      svg {
        height: 80%;
        width: 80%;
        cursor: pointer;
      }
    }
  }
  .popover-body {
    padding: 0 0.75rem 0.75rem 0.75rem;
  }
  .custom-popover-body {
    border-radius: 0.625rem;
    background: #e3eeff;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    * {
      font-family: "Inter";
    }
    .custom-popover-keyword {
      margin: 0.25rem;
      font-size: 0.75rem;
      background-color: #fff;
      padding: 0.5rem;
      border-radius: 10px;
    }
  }
}
