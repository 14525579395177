.target-keyword-info--container {
  border-bottom: 1px solid #dadce0;
  padding-top: 20px;
}
.target-keyword-info--title {
  font-family: "Lato";
  // font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
  padding-bottom: 10px;
}

.target-keyword-info--desc {
  font-family: Inter;
  font-size: 14px;
  line-height: 24px;
  color: #666666;
  font-weight: 400;
  padding-bottom: 10px;
  .desc-title {
    color: #333333;
    font-weight: 600;
  }
}
