.sorting-dropdown {
  display: block;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
  border-radius: 0.474588125em;
  padding: 1em;
  z-index: 1000;
  cursor: pointer;
  right: 0.625em;
  margin-top: 0.5em;
  .dropdown-item {
    font-size: 0.875em;
    line-height: 1.25em;
    color: #333333;
    padding: 0.75em 0;
    border-bottom: 1px solid #efefef;
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
    &:hover {
      background-color: transparent;
    }
  }
}
.sort-btn-bg-clr {
  background: #dddddd;
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #333333;
  margin-left: 8px;
  user-select: none;
}
.default-disabled {
  font-size: 0.75em;
  line-height: 1.25em;
  color: #9e9e9e;
}
