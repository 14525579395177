.no_result_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 146px);
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 152.2%;
    text-align: center;
    color: #9e9e9e;
  }
}
