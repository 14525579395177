.image-video-card-container {
  width: 100%;

  .image-video-content {
    // border:1px solid black;
    width: 161px;
    margin: 10px;
    padding: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    cursor: pointer;
    border-radius: 4px;
    border: 2px solid transparent;
  }

  .image-video-content-selected {
    // width: 157px;
    width: 161px;
    margin: 10px;
    padding: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    cursor: pointer;
    border-radius: 4px;
    border: 2px solid #0869fb;
  }

  .image-video-card-file {
    // width: 157px;
    width: 100%;
    // height: 100px;
    height: 133px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
    object-fit: unset;
  }

  .image-video-card-file-selected {
    // width: 153px;
    width: 100%;
    // height: 100px;
    height: 133px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
    object-fit: unset;
  }

  .image-video-content-div {
    position: relative;
  }

  .image-video-content-dropdown {
    position: absolute;
    top: -12px;
    right: 24px;
  }

  .image-video-content-dropdown-toggle {
    background-color: transparent !important;
    width: 20px;
    float: right;
  }

  .image-video-content-dropdown-toggle:hover {
    background-color: transparent !important;
  }

  .image-video-content-head {
    display: flex !important;
    justify-content: space-between !important;
  }

  .image-video-para {
    font-weight: 400;
    font-size: small;
    line-height: 24px;
    overflow: hidden;
    max-width: 75ch;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 4px;
    font-family: "Inter";
    margin-bottom: 0px;
  }

  .video-play-btn {
    position: absolute;
    position: absolute;
    width: 40.34px;
    height: 28.93px;
    left: 8px;
    top: 91px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: black;
    opacity: 0.7;
  }

  .image-video-content-head-dot {
    font-size: 38px;
    font-weight: bold;
    z-index: 9999999999;
  }

  .image-video-mark {
    position: absolute;
    top: 12px;
    left: 10px;
  }
}
