.pin-popup{
  position: absolute;
  top: 70px;
  margin-left: 594px;
  background:#fff;
  box-shadow: 0 0 12px rgb(0 0 0 / 15%);
  border-radius: 10px;
  padding: 15px 0px;
  z-index: 99;
  transform: translate3d(0,0,0);
  .header{
    padding: 0px 0px 10px 15px;
    .title{
      font-weight: 500;
    }
    .subtitle{
      font-size: 13px;
      font-weight: 500;
    }
  }
  .choose-pin--container{
    .choose-pin--position{
      display:flex;
      justify-content: space-between;
      padding: 5px 15px;
      border-left: 5px solid transparent;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
      &:hover,&.active{
        background: #eeeeee;
        border-left-color:#5185EC;
      }
      .left{
        padding-right: 5px;
        .title{
          font-weight: 500;
        }
      }
      .right{
        img{
          width: 200px;
        }
      }
      
    }
  }
}