.new-daterange-calendar-view {
    .month-year-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .next-prev-button {
            display: block;
            width: 24px;
            height: 24px;
            margin: 0 0.833em;
            padding: 0;
            border: 0;
            border-radius: 5px;
            background: white;
            &.prev-button {
                i {
                    border-width: 4px 6px 4px 4px;
                    border-color: transparent rgb(52, 73, 94) transparent transparent;
                    transform: translate(-3px, 0px);
                }
            }
            &.next-button {
                i {
                    margin: 0 0 0 7px;
                    border-width: 4px 4px 4px 6px;
                    border-color: transparent transparent transparent rgb(52, 73, 94);
                    transform: translate(3px, 0px);
                }
            }
            i {
                display: block;
                width: 0;
                height: 0;
                padding: 0;
                text-align: center;
                border-style: solid;
                margin: auto;
            }
        }
        .month-year-picker {
            .month-picker {
                margin: 0 0.3em;
            }
            .year-picker {
                margin: 0 0.3em;
            }
            select{
                appearance: none;
                border: 0;
                background: transparent;
                padding: 10px 30px 10px 10px;
                border-radius: 4px;
                outline: 0;
                color: #3e484f;
                background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
                background-position: right 8px center;
                cursor: pointer;
                text-align: center;
                border: none;
                font-size: 0.875rem;
            }
        }
    }
    .display-dates {
        display: flex;
        justify-content: space-between;
        margin: 0.833em;
        .display-item {
            border-radius: 4px;
            background-color: rgb(255, 255, 255);
            box-shadow: 0 1px 2px 0 rgb(35 57 66 / 21%);
            border: 1px solid transparent;
            flex: 1 1;
            width: 0;
            margin-right: 0.3em;
            input {
                color: #7d888d;
                cursor: pointer;
                height: 2.5em;
                line-height: 2.5em;
                border: 0px;
                background: transparent;
                width: 100%;
                font-size: 12px;
                text-align: center;
                outline: none;
            }
        }
    }
    .each-cell {
        background: transparent;
        user-select: none;
        border: 0;
        padding: 0;
        line-height: 3.000em;
        height: 3em;
        text-align: center;
        color: #1d2429;
        flex-basis: calc(100% / 7);
        box-sizing: inherit;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        &.selected {
            background-color: #3d91ff;
            color: #FFF;
        }
        &.selected-star-date {
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
        }
        &.selected-end-date {
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
        }
        &.disabled-date {
            background-color: #f8f8f8;
        }
        &.hovered-start-date{
           
            border-left: 1px solid #3d91ff;
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
        }
        &.hovered-date{
            border-top: 1px solid #3d91ff;
            border-bottom: 1px solid #3d91ff;
        }
    }
}

