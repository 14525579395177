.tableWrap {
  height: calc(100% - 58px);
  overflow: auto;
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th {
    padding: 16px;
    padding-left: 15px;
    text-align: left;
  }
}
.pyxis-table-container::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}

.pyxis-table-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
}

.pyxis-table-container::-webkit-scrollbar-thumb {
  background-color: rgb(184, 182, 182);
  outline: 1px solid rgb(142, 169, 197);
  border-radius: 0.3rem;
}
.loadMore {
  margin: 10px;
  margin-left: 20px;
  background-color: rgb(27, 123, 240);
  color: white;
  cursor: pointer;
}
.disabled {
  background-color: rgb(192, 195, 198);
  margin: 10px;
  margin-left: 20px;
  color: white;
  cursor: pointer;
}
.secondary-text {
  color: #666666;
}
.popover-style {
  padding: 10px;
  font-size: 12px;
  font-weight: 500;
  .sub-header {
    color: #000;
  }
  .value {
    color: #333;
  }
}
.pyxis-table-header-container .dropdown.hoverable .dropdown-menu{
  max-height: 250px;
  .dropdown-item {
    max-height: 190px;
    overflow: scroll;
  }
}
.MuiAlert-icon{
  margin-right: 0 !important;
}