.chart-tab-header-container:nth-of-type(1) {
  padding-left: 0px;
}
.chart-tab-header-container:nth-last-of-type(1) {
  border-right: none;
}
.add-metrics-dropdown {
  .dropdown-menu {
    .dropdown-item {
      max-width: 14rem;
    }
  }
}
.chart-tab-header-container {
  display: flex;
  align-items: center;
  padding: 0px 1rem;
  border-right: 1px solid #dddddd;
  position: relative;
  .campaign-checkbox {
    label {
      &:before {
        padding: 8px;
        margin-right: 10px;
        border-color: inherit !important;
      }
      &:after {
        top: 7px !important;
        left: 7px !important;
        width: 5px !important;
        height: 9px !important;
        border-color: inherit !important;
      }
    }
  }

  .campaign-label {
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #5f6771;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 6rem;
    .metric-tooltip {
      position: unset;
      .custom-tooltip-layout {
        top: 40px;
        width: fit-content;
      }
    }
  }
  .campaign-cumulative {
    font-size: 11px;
  }
  .chart-tab-delete-container {
    display: none;
    position: absolute;
    top: -14px;
    right: 6px;
    cursor: pointer;
  }
}
.chart-tab-header-container:hover {
  .chart-tab-delete-container {
    display: block;
  }
}
.add-metrics-dropdown {
  padding: 0 1rem !important;
  cursor: pointer;
  .dropdown-menu {
    inset: 40px auto auto 35px !important;
    max-height: 200px;
    overflow: scroll;
    width: 250px;
    .d-flex {
      padding: 4px 0px;
      font-size: 13px;
      line-height: 24px;
      color: #000000;
    }
  }
  .checkbox {
    label {
      &::after {
        border-color: inherit !important;
      }
      &::before {
        border-color: inherit !important;
      }
    }
  }
}
.add-metrics-dropdown-left {
  .dropdown-menu {
    inset: 40px auto auto -220px !important;
  }
}
.add-metrics-dropdown-disabled {
  cursor: not-allowed;
  svg {
    cursor: not-allowed;
  }
  .dropdown-menu {
    display: none;
  }
  .tooltip-container .tooltip {
    width: 180px;
    left: -150px;
    top: 35px;
  }
  .custom-tooltip-layout .tootltip-wrapper {
    color: black;
  }
}

.add-metrics-empty {
  padding: 0 !important;
}
