.dropdown-container {
  position: relative;
  .dropdown-item-container {
    display: block;
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin: 4px 0px;
    z-index: 1000;
    cursor: pointer;
    .dropdown-item {
      padding: 4px 8px;
      width: auto;
    }
  }
}


.dropdown-up-ui {
  display: inline-block;
  .dropdown-item-container {
    @extend .dropdown-item-container;
    .dropdown-item {
      padding: 5px 20px 5px 20px;
    }
    top: 45px;
  }
}