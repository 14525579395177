.radio-input {
  .display-flex {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
}
.radio-input-input {
  margin-left: 16px;
}
