$large: 1600px;
$medium: 1500px;
$small: 1400px;
.planned-spends {
  .currency-prefix {
    font-size: 1.3216125em !important;
    line-height: 1.625em;
    color: #616161;
  }
  .planned-spends-input,
  .Mui-focused {
    font-weight: 600;
    font-size: 1em !important;
    line-height: 1.25em;
    border: 1px solid rgba(43, 113, 250, 0.1);
    box-shadow: 0px 4px 24px rgba(35, 40, 60, 0.05);
    border-radius: 8px;
    color: #616161;
    padding: 0em 0.75em;
    &:focus,
    &:hover {
      border: 1px solid rgba(43, 113, 250, 0.1) !important;
      box-shadow: 0px 4px 24px rgba(35, 40, 60, 0.05) !important;
    }
  }
}

.table-budget-breakdown {
  border: 1px solid rgba(43, 113, 250, 0.1);
  box-shadow: 0px 10px 24px rgb(35 40 60 / 5%);
  border-radius: 8px;
  width: 100% !important;
  .backgroud-light {
    background: #f8f9fe;
  }
  .border-right-separator {
    border-right: 1px solid #dddddd;
  }
  .border-bottom-none {
    border-bottom: none !important;
  }
  .common-header {
    font-weight: 700;
    font-size: 0.8em !important;
    line-height: 1.1875em !important;
    color: #555555;
    padding: 0.5em 1.5em;
  }
  .cell-value {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 1.0625em;
    color: #555555;
    min-width: 9em;
    max-width: 10em;
    .value-item {
      border: none;
      border-bottom: 1px solid #bdbdbd;
      border-radius: 0;
      padding: 0 0 0.3em 0.8em;
      font-weight: 500;
      font-size: 0.875em !important;
      line-height: 1.0625em;
      color: #808080;
      margin-left: 12px;
    }
  }
  .currency-prefix {
    position: absolute;
    color: #808080;
  }
  .left-sticky {
    left: 0;
    position: sticky;
  }
  .z-20 {
    z-index: 20;
  }
  .z-30 {
    z-index: 30;
  }
  ::-webkit-scrollbar {
    //**** Will search  alternate in future ***//
    height: auto;
    width: auto;
  }
}
.please-note-text {
  font-style: italic;
  font-weight: 400;
  font-size: 0.75em;
  line-height: 0.9375em;
}
.apply-button {
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1.5em;
  padding: 0.5em 1.5em;
}
.footer-content {
  padding: 1em;
  background: #ffffff;
  box-shadow: 0px -4px 4px rgb(0 0 0 / 5%);
  border-radius: 0px 0px 10px 10px;
}
.section-disable {
  opacity: 0.5;
  pointer-events: none;
}
.budget-constraints-tags {
  font-weight: 600 !important;
  font-size: 0.6em !important;
  line-height: 1em !important;
  @media screen and (max-width: $medium) {
    font-size: 0.5em !important;
    line-height: 0.9em !important;
  }
  color: #434343;
  .calendar-icon {
    width: 1.2em;
    height: 1.2em;
  }
  .graph-icon {
    width: 1.4em;
    height: 1.2em;
  }
}
.border-separator-right {
  border-right: 1px solid #e6e6e6 !important;
}
.constraint-box-shadow {
  background: #ffffff;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 0.6em 0.4em;
}
.warning-icon {
  svg {
    width: 72px;
    height: 72px;
  }
}
.constraint-type {
  .title-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #656565;
  }
  .MuiToggleButtonGroup-root {
    padding: 10px;
    background: #F7F8FC;
    border-radius: 8px;
    margin-bottom: 15px;
    margin-top: 10px;
    .MuiToggleButton-root {
      border: none;
      text-transform: capitalize;
      color: #666666;
      font-size: 14px;
      line-height: 16px;
      padding: 12px 16px;
      font-weight: 400;
      &.Mui-selected {
        background: #0869FB;
        border-radius: 6px;
        color: #FFFFFF;
        &.Mui-disabled {
          background: rgba(0, 0, 0, 0.26);
        }
      }
    }
  }
}
#setup-changed-popover {
  z-index: 40 !important;
}