.create-ad-btn {
  padding: 10px !important;
}
.publish-cluster-table-container {
  .addgroup {
    color: #666666;
    border-bottom: 1px solid #dddddd;
  }
  .select-ad-dropdown-btn {
    padding: 0.6rem 1.2rem !important;
    width: 11rem;
    .btn-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    label {
      margin-bottom: 0rem !important;
    }
  }
  .ad-dropdown {
    position: initial;
    .dropdown-item {
      padding: 4px 16px;
    }
  }
  .radio {
    .display-block {
      display: block;
    }
    .radio-input {
      margin-right: 1rem;
    }
  }
  .form-control:focus {
    border-color: #dddddd;
    box-shadow: none;
  }
  .color-light-grey {
    color: #7a7a7a;
  }
  .edit-links {
    color: #0869fb !important;
    text-decoration: underline !important;
    cursor: pointer;
  }
  .publish-cluster-td {
    font-family: Roboto;
    font-size: 14px;
    line-height: 24px;
    color: #212121;
    padding: 16px;
    
    
  }
  .select-ad-group {
    display: flex;
    align-items: center;
    width: 100% !important;
    justify-content: space-between;
    .select-ad {
      font-size: 14px;
    }
  }
  .ad-group-dropdown {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    border: none;
  }
  .ad-group-dropdown-item {
    font-family: Roboto;
    font-size: 14px;
    line-height: 24px;
    color: #414447;
  }
  .select-ad-group-dropdown {
    position: relative !important;
  }
  .select-ad-dropdown {
    width: 180px;
    background: #f2f2f2 !important;
    border-radius: 5px;
  }

  .ad-group-detail {
    padding-top: 5px;
    text-align: center;
    .ad-group-name {
      font-family: Roboto;
      font-size: 14px;
      line-height: 20px;
      color: #212121;
    }
    .campaign-name {
      font-family: Roboto;
      font-size: 14px;
      line-height: 24px;
      color: #b3b3b3;
    }
    .edit-campaign {
      color: #0d6efd;
      font-size: 14px;
      line-height: 24px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
