/* Mini Table Styles*/
.mini-table {
  padding-bottom: 30px;
  .table-title {
    padding-bottom: 10px;
    .tagline {
      font-size: 14px;
      line-height: 16px;
    }
  }
  .tooltip-container {
    display: inline;
    position: relative;
    .custom-tooltip-layout {
      right: 0;
    }
  }
  .table-responsive {
    border-radius: 10px;
    border: 1px solid #dddddd;
    .table {
      thead {
        box-shadow: none;
        tr {
          box-shadow: 0px 1px 12px rgb(0 0 0 / 10%);
          th {
            line-height: 16px;
            padding: 12px 0 14px;
            font-size: 14px;
            width: auto;
            border-bottom-color: transparent !important;
            input {
              margin-left: 15px;
            }
          }
        }
      }
      tbody {
        tr:last-child {
          td {
            border-width: 0;
          }
        }
        td {
          line-height: 16px;
          padding: 12px 8px;
          background-color: #fff;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 12rem;
          input {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
