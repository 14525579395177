.accordion-ui-container {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .accordion-body {
    height: 0;
    overflow: hidden;
    padding: 0rem;
    -webkit-transition: height 0.7s ease;
    -moz-transition: height 0.7s ease;
    -ms-transition: height 0.7s ease;
    -o-transition: height 0.7s ease;
    transition: height 0.7s ease;
  }
  .accordion-open {
    height: max-content;
  }
}
