#dropdown-toggle-white {
  background: white !important;
}
#paramter {
  width: 150px !important;
  min-width: 0px !important;
}
#menu {
  width: 60px !important;
  min-width: 0px !important;
  .disabled {
    margin: 0px;
  }
}
