.tms-container {
  font-family: interBlack;
  background-color: #f2f2f2;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 7px;
}


.tms-loader-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 999999999999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
}