.input-label-container {
  width: 100%;
  padding: 0px 16px 0px;
  .input-label--input {
    border: 0;
    border-bottom: 1px solid #dddddd;
    width: 100%;
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
    outline: none;
    resize: none;
  }
  .input-label--label {
    font-family: Roboto;
    font-size: 12px;
    line-height: 24px;
    color: #a1a1a1;
  }
  .input-label--validation {
    font-family: Roboto;
    font-size: 14px;
    line-height: 24px;
    color: #666666;
  }
  .limit-reached {
    font-weight: 500;
    color: #004489;
  }
}
