.selectable-card-container{
    width: 100%;
    // display: flex;
    // flex-direction: row;
    // align-items: center;

    .selectable-card-content{
        height: 100px;
        width: 100px;
        //box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        background: #FFFFFF;
        border: 1px solid rgba(192, 192, 192, 0.3);
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        justify-content:center;
        align-items: center;
        cursor: pointer;
        margin: 10px;
    }

    .selectable-card-content-selected{
        height: 100px;
        width: 100px;
        //box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        background: #FFFFFF;
        border: 2px solid #0869FB;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        justify-content:center;
        align-items: center;
        cursor: pointer;
        margin: 10px;
    }
    .selectable-card-content-div{
      display:flex;
      justify-content:center;
      align-items: center;
      flex-direction: column;
    }

    .selectable-card-content-para{
      font-size: small;
      color: #000000;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
    .selectable-card-content-para-selected{
      font-size: small;
      color: #000000;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      }
}