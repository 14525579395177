.accounts-manager-box {
  width: auto;
  height: 92vh;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 24px;
  border-radius: 8px;

  .ad-accounts {
    position: absolute;
    top: 42px;
    left: 0;
    width: 150px;
    font-size: 14px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    z-index: 999999;
    background-color: white;

    a {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      z-index: 999999;
      gap: 8px;
      padding-left: 10px;
      text-decoration: none;

      .ad-accounts-icon {
        width: 24px;
        height: 24px;
        background: #fafafa;
        border: 1px solid #f0f0f0;
        border-radius: 50%;
        padding-left: 0px;

        svg {
          width: 24px;
          height: 24px;
          padding: 2px 4px 2px 4px;
        }
      }
    }
  }

  .accounts-manager-header {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f9fe;
    padding: 0 24px;
    border-radius: 8px 8px 0 0;
  }
}

.adaccount-stats-table {
  border-radius: 10px;
  width: 100%;
  z-index: 0;
  overflow: scroll;
  max-height: 82%;
  padding: 0 24px;
  .table-head {
    height: 60px;
    align-items: center;
    border-bottom: 1px solid #e7eaee;
  }

  .table-row {
    display: grid;
    grid-template-columns: 1.25fr 1fr 1fr 1fr 1fr;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    font-family: "Inter";
    color: #111827;
    align-items: center;
    border-bottom: 1px solid #e7eaee;
    padding: 16px 0;
    &:last-child {
      border-bottom: none;
    }
    .account-name {
      display: grid;
      grid-template-columns: 1fr;
      gap: 4px;
      .token-message {
        width: max-content;
        padding: 4px 8px;
        font-size: 10px;
        border-radius: 4px;
        font-weight: 400;
        &.token-expired {
          color: #e84b47;
          background: #ffe5e4;
        }

        &.token-warning {
          color: #f99f35;
          background: #fef5eb;
        }

        &.token-daysLeft {
          color: #1abfa1;
          background: #e2f9f4;
        }
      }
    }

    .tokens-stat {
      text-decoration-line: underline;
      color: #0869fb;
      cursor: pointer;

      &.zero-tokens {
        cursor: default;
        color: #999999;
        text-decoration: none;
      }
    }

    .reconnect-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      grid-gap: 4px;
      grid-gap: 4px;
      gap: 4px;
      height: 28px;
      border: 1px solid #0869fb;
      border-radius: 8px;
      color: #0869fb;
      width: fit-content;
      padding: 0 8px;
      background-color: #fff;
      text-decoration: none;
      cursor: pointer;
      position: relative;
      user-select: none;

      .blocked-msg {
        display: none;
        background: #313131;
        padding: 8px;
        position: absolute;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 13px;
        color: #ffffff;
        border-radius: 8px;
        width: 212px;
        bottom: 100%;
        right: 0;
      }

      &:hover {
        .blocked-msg {
          display: block;
        }
      }

      &.connect {
        color: #63be09;
        border: 1px solid #63be09;

        svg {
          path {
            fill: #63be09;
          }
        }
      }

      &.blocked-connect {
        &:active {
          pointer-events: none;
        }
        cursor: not-allowed;
        color: #999999;
        border: 1px solid #999999;

        svg {
          path {
            fill: #999999;
          }
        }
      }
    }
  }
}

.token-modal {
  background-color: white !important;
  width: calc(100% - 130px);
  border-radius: 4px;
  padding: 24px !important;

  .token-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      color: #1c1e21;
      gap: 8px;

      span:first-child {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }

      span.dot {
        width: 4px;
        height: 4px;
        background: #dddddd;
        border-radius: 50%;
      }

      span:last-child {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
      }
    }

    svg.close-icon {
      width: 12px;
      margin-right: 12px;
      cursor: pointer;
    }
  }

  .token-account-id {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #999999;
    margin-top: 4px;
  }

  .token-table {
    border: 1px solid #dddddd;
    border-radius: 10px;
    padding: 0 50px;
    margin-top: 12px;

    .table-head {
      border-bottom: 2px solid #313131;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }

    .table-row {
      margin: 0 -50px;
      padding: 0 50px;
      height: 50px;
      display: grid;
      grid-template-columns: 3fr 3fr 3fr 1fr;
      align-items: center;
    }

    .table-body {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #262626;

      .table-row {
        .token-owner {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 16px;

          .lock-container {
            display: inline-block;
            position: relative;

            .lock-tooltip {
              display: none;
              padding: 12px;
              position: absolute;
              background: #313131;
              border-radius: 8px;
              font-weight: 400;
              font-size: 12px;
              line-height: 15px;
              color: #ffffff;
              width: 108px;
              bottom: 7px;
              left: 9px;
            }
          }

          .lock-icon {
            display: none;
            cursor: pointer;
          }
        }

        .token-access-stat {
          color: #63be09;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;
          font-size: 16px;
          line-height: 19px;

          svg {
            width: 15px;
          }

          &.token-error {
            color: #e84b47;

            svg {
              path {
                fill: #e84b47;
              }
            }
          }

          &.token-warning {
            color: #f5a623;

            svg {
              path {
                fill: #f5a623;
              }
            }
          }
        }

        .delete-container {
          position: relative;

          .delete-tooltip {
            display: none;
            position: absolute;
            background: #313131;
            border-radius: 8px;
            padding: 24px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #ffffff;
            width: 160px;
            bottom: 0;
            left: 24px;
          }

          &:hover {
            .delete-tooltip {
              display: block;
            }
          }
        }

        .delete-icon {
          cursor: pointer;
        }

        &:hover {
          background-color: #f2f2f2;

          .lock-icon {
            display: block;
          }
        }

        &.primary-token {
          .lock-container:hover {
            .lock-tooltip {
              display: block;
            }
          }
          .lock-icon {
            display: block;

            path {
              fill: #2008fb;
            }
          }
        }
      }
    }
  }
}

.account-manager-modal {
  position: fixed;
  left: 5%;
  top: 0;
  height: 100vh;
  font-size: 7px;
  display: flex;
  right: 0.5%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.35);
  z-index: 999;
}
