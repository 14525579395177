
    select:required:invalid {
    color: gray;
  }
  option[value=""][disabled] {
    display: none;
  }
  option {
    color: black;
  }
