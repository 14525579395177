.side-bar-accordian-container {
  * {
    font-family: "Inter" !important;
    font-size: 1rem;
    font-weight: 600 !important;
  }
  ::before {
    content: none;
    border: none;
    height: 0px !important;
    top: 0px;
  }
  .no-padding-right {
    .MuiButtonBase-root {
      padding-right: 0px !important;
    }
  }
  .MuiListItem-root {
    .MuiButtonBase-root {
      .MuiPaper-root {
        .MuiButtonBase-root:hover {
          background-color: #e9f2ff !important;
          border-radius: 0.5rem;
        }
      }
    }
  }
  .active-nav-item {
    &.bg-active-tab {
      background: #e9f2ff;
      border-radius: 0.5rem;
    }
    color: #0869fb;
    .MuiListItemIcon-root {
      svg * {
        stroke: #0869fb;
      }
    }
    &.no-stroke {
      .MuiListItemIcon-root {
        svg * {
          stroke: none;
        }
      }
    }
  }
  .closed-active-item {
    .MuiPaper-root {
      background: #e9f2ff;
    }
  }
  .side-bar-accordian {
    &.MuiAccordionDetails-root {
      padding-bottom: 0px;
    }
  }
}
