.font-inter {
  * {
    font-family: "Inter" !important;
  }
}
.font-lato {
  font-family: "Lato" !important;
}
.font-8 {
  font-size: 0.5rem !important;
}
.font-10 {
  font-size: 0.625rem !important;
}
.font-12 {
  font-size: 0.75rem !important;
}
.font-14 {
  font-size: 0.875rem !important;
}
.font-16 {
  font-size: 1rem !important;
}
.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-700 {
  font-weight: 700 !important;
}

.color-black {
  color: #000;
}
.color-black-333 {
  color: #333;
}
