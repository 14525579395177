.tai-style {
  .ai-initiation-node {
    height: 50px;
    border: 1px solid #eee;
    padding: 5px;
    border-radius: 5px;
    background: white;
  }

  .ai-initiation-node label {
    display: block;
    color: #777;
    font-size: 12px;
  }

  .brand::placeholder {
    color: #000000 !important;
  }

  .multiselect {
    width: 16.6rem !important;
    padding: 0.25rem 0 !important;
  }

  .dropdown-menu {
    width: 100% !important;
    height: 12.5rem !important;
    background-color: rgb(250, 250, 250) !important;
    z-index: 50 !important;
    border: 1px solid rgb(240, 240, 240) !important;
    box-shadow: rgb(0 0 0 / 5%) 0px 4px 14px !important;
    border-radius: 8px !important;
    /* max-width: 95% !important; */
    /* max-height: 12.5rem !important; */
    /* height: 100% !important; */
    overflow-y: auto !important;
  }

  .dropdown-menu .d-flex {
    padding: 0.25rem !important;
  }

  .dropdown-menu .text-truncate {
    font-size: 0.75rem !important;
  }

  .btnUI {
    padding: 10px !important;
  }

  .btnUI.secondary {
    width: 100% !important;
    max-width: 100% !important;
    /* padding: 10px !important; */
    height: 48px !important;
    font-size: 13px !important;
    padding: 0.8rem !important;
  }

  .btnUI.secondary svg {
    width: 10px !important;
    height: 7px !important;
  }

  .btnUI.secondary:hover {
    background-color: #f2f2f2 !important;
  }

  .input-box {
    font-size: 0.875rem !important;
    padding: 0.8rem !important;
    border-radius: 0.5rem;
  }
  input.input-box:disabled {
    opacity: 0.8;
    -webkit-text-fill-color: #1010104d;
    -moz-text-decoration-color: #1010104d;
  }
  .input-box:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #f2f2f2 inset !important;
  }

  .run-btn {
    margin: 0 !important;
    width: 5.5rem;
    height: 2.5rem;
  }
  .collapsed-header {
    * {
      font-family: "Inter";
    }
    display: flex;
    flex-direction: row;
    width: 100%;
    font-weight: 600;
    font-size: 1rem;
    .header-icon {
      margin-left: auto;
    }
    .collapsed-heading {
      display: flex;
      align-items: center;
    }
  }
  .collapsed-header-hr {
    margin: 0px !important;
    width: 100% !important;
    opacity: 0.1 !important;
  }
}
