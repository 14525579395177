.createad-drawer-body {
  display: flex;
  flex-grow: 1;
  max-height: calc(100% - 200px);
  .drawer-panel {
    border: 1px solid #dadce0;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    width: 100%;
    padding: 24px;
    overflow: scroll;
  }
  .drawer-right-panel {
    padding: 0px 24px 24px;
    margin-left: 24px;
    background: #f5f6f7;
  }
}
