// .form-group {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
.label-switch {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #3e3e3e;
  font-weight: 500;
  margin: 0;

  .switch {
    width: 38px;
    height: 18px;
    border-radius: 100px;
    background-color: #d9d9d9;
    position: relative;
    transition: all 0.3s;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #62676e;
      transition: all 0.3s;
    }
  }
}
input {
  &[type="checkbox"] {
    display: none;

    &:checked {
      + label {
        &.label-switch {
          .switch {
            background-color: #dae3f3;

            &:after {
              left: calc(100% - 20px);
              background-color: #507ad7;
            }
          }
        }

        &.label-check {
          .circle {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
