.campaigns-container {
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;

  .error {
    color: #ff0000;
    font-size: 12px;
    padding-left: 5px;
  }

  .campaigns {
    max-height: 88vh;
    padding-bottom: 45px;

    .campaign-table-google {
      margin: 24px;
      padding-bottom: 30px;
      box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
      border-radius: 10px;

      .table > :not(:last-child) > :last-child > * {
        border-bottom: 1px solid #8a8a8a;
      }

      th {
        font-size: 12px;
        font-weight: bold;
        padding-left: 30px;
      }

      td {
        padding-left: 30px;
      }
    }

    .profitability {
      background: #ffffff;
      box-shadow: 0px 1px 12px rgb(0 0 0 / 10%);
      border-radius: 10px;
      margin: 24px;
      padding: 0 0 30px;

      hr {
        margin: 0 24px;
      }

      .campaign_sub_heading {
        font-size: 12px;
        padding-left: 24px;
        color: #666666;
      }

      .metric_sub_heading {
        font-size: 12px;
        padding-left: 24px;
        color: #666666;
        margin: 0;
      }

      .slider-rc {
        display: flex;
        margin: 0 24px;

        p {
          margin: 0;
          font-size: 11px;
          align-self: flex-end;
        }

        .slider-container {
          width: fit-content;
          .slider-percent-label {
            display: flex;
            position: relative;
            padding-top: 15px;

            p {
              position: absolute;
            }
          }
        }
      }

      .profitability_roi_metric {
        padding: 10px 24px;

        .info-urgent {
          color: #f58a8a;
          font-size: 12px;
        }

        .btn-add-more {
          display: flex;
          justify-content: space-around;
          padding: 0;
          border-radius: 10px;
          font-size: 12px;
          width: 150px;
          height: 35px;

          .btn-plus {
            font-size: 24px;
          }
        }
      }
    }
  }

  .campaign-footer {
    height: 60px;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    background: #ffffff;
    padding-right: 30px;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 10px 10px;

    .btn-cancel,
    .btn-apply {
      margin: 12px 5px;
      border-radius: 5px;
      font-size: 12px;
      border-radius: 10px;
      border: none;
      width: 80px;

      &:disabled {
        opacity: 0.4;
      }
    }

    .btn-apply {
      background: #0869fb;
      color: #fff;
    }

    .btn-cancel {
      background: #f2f2f2;
    }
  }
}
.montage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: white;
  border-radius: 4px;
  padding: 20px;
}
.heading {
  text-align: center;
  span {
    font-weight: 800;
    font-size: x-large;
  }
}
.heading-with-diff-color {
  color: #0869fb;
  font-weight: 500;
}
.montage-video {
  background-color: #8a8a8a;
  width: 70%;
  margin: 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  video {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
  iframe {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
}
.campaign-button {
  background-color: #0869fb;
  border: none;
  border-radius: 4px;
  height: 50px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  text-decoration: none;

  .setup-campaign-icon {
    margin-left: 1rem;
  }
}
.campaign-button:hover {
  text-decoration: none;
  color: #ffffff;
}
.montage-heading {
  font-weight: bolder;
}
.montage-campaign {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
  border-radius: 4px;
  padding: 20px;
}
.launch-campaigns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 1.5%;

  .campaign-btn1 {
    background-color: #0869fb;
    width: 15%;
    height: auto;
    padding: 0.5%;
    border: none;
    border-radius: 4px;
    color: white;
    font-family: inter;
    text-decoration: none;
    text-align: center;
  }
  .campaign-btn2 {
    background-color: #edf3fe;
    width: 15%;
    height: 25px;
    border: none;
    border-radius: 4px;
    color: #0869fb;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: smaller;
  }
}
.campaign-ul {
  padding: 0px;
  padding-top: 5px;
  width: 100%;
}
.campaign-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 5px;
  height: 80px;

  .campaign-name {
    display: flex;
    align-items: center;
    // justify-content: center;
    width: 20%;
    img {
      width: 30px;
      height: 30px;
      background-color: #8a8a8a;
      margin: 10px;
      border-radius: 50%;
    }

    span {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-weight: 700;
      font-size: small;
    }
  }
  span {
    font-size: x-small;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 700;
  }
  small {
    color: #8a8a8a;
  }
}
.campaign-details {
  display: flex;
  flex-direction: column;
  width: 20%;
}
.creative-library {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  button {
    width: 20%;
    height: 50%;
    border: none;
    margin: 10px;
    outline: none;
    border-radius: 4px;
  }
}

.montage-video-panel-nav {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 2%;
  border-bottom: 1px solid #e0e0e0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  h6 {
    padding-left: 2%;
  }
  i:hover {
    cursor: pointer;
  }
}
.montage-video-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 20px;
  overflow: scroll;

  .video-container {
    width: 100%;
    .video-tile-montage {
      width: 24%;
      height: 150px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e0e0e0;
      margin: 0.5%;
      padding: 0%;
      position: relative;
      video {
        height: 100%;
        width: 100%;
        object-fit: fill;
        border-radius: 6px;
      }
      .buttons-montage {
        position: absolute;
        background-color: #e0e0e0;
        opacity: 0.8;
        border-radius: 15%;
        z-index: 999;
        top: 2px;
        left: 84%;
        cursor: pointer;
      }
      .buttons-montage2 {
        position: absolute;
        border-radius: 10%;
        cursor: pointer;
        z-index: 999;
        top: 75%;
        left: 0%;
      }
    }
  }
}
.montage-no-video-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 20px;
  overflow: scroll;

  .video-container {
    width: 100%;
    .video-tile-montage {
      width: 24%;
      // height:30%;
      height: 150px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e0e0e0;
      margin: 0.5%;
      padding: 0%;
      video {
        height: 100%;
        width: 100%;
        object-fit: fill;
        border-radius: 6px;
      }
      .buttons-montage {
        position: relative;
        z-index: 999;
        top: 10px;
        left: -10px;
      }
    }
  }

  .montage-no-videos {
    background-color: #f6f6f6;
    height: 70%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #e0e0e0;

    h5 {
      padding-top: 5%;
      color: black;
      opacity: 0.7;
    }
    p {
      color: black;
      opacity: 0.7;
      text-align: center;
    }
  }
}

.campaign-modal-container {
  position: absolute;
  right: 0%;
  .campaign-delete-button {
    background-color: #0869fb;
    color: white;
  }
}
