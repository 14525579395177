.creative-replacement-container {
  height: 50%;
  width: 100%;
  position: relative;

  .ad-container-body.tbp {
    height: 85% !important;
  }

  .cr-header {
    position: relative;
    background: #f5f5f5;
    padding: 0.6rem;
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 1.05rem;
    line-height: 1.425rem;
    display: flex;
    flex-direction: row;
    color: #303030;
    justify-content: center;
    align-items: center;

    svg.cr-replacement-icon {
      width: 20px;
      margin-right: 10px;
    }

    .cr-dots {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .cr-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 44px);

    .cr-enabled {
      width: 100%;
    }

    button.enable-cr {
      width: 15.625rem;
      height: 2.75rem;
      background: #0869fb;
      border-radius: 6px;
      border: none;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      color: #fff;
      margin-bottom: 1rem;
    }

    .cr-info-message {
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 1.375rem;
      display: flex;
      align-items: center;
      text-align: center;
      color: rgba(0, 0, 0, 0.4);
      margin-top: 1rem;
    }
  }

  .cr-config-modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    .cr-modal-content {
      padding: 12px 20px 20px;
      flex: 1;
      min-height: 0;
      overflow: auto;
      .cr-input {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 14px;
        }

        .pixis-checkbox {
          width: 22px;
          height: 22px;
        }

        .cr-input-days {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          min-width: 84px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #666666;

          input {
            border: 1px solid #c7cad6;
            border-radius: 4px;
            width: 46px;
            height: 26px;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            margin-right: 16px;
          }
        }
      }

      .cr-modal-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
        margin-bottom: 8px;
      }

      .cr-modal-subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #666666;
        margin-bottom: 10px;
      }
    }

    .cr-modal-footer {
      border: 1px solid #e8e8e8;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // align-items: center;
      padding: 12px 16px;
      height: 105px;
      button {
        background: transparent;
        padding: 6px 24px;
        border-radius: 6px;
        font-size: 14px;
        line-height: 24px;
        border: none;
        height: 40px;

        &:disabled {
          opacity: 0.6;
        }

        &.cr-modal-btn {
          font-weight: 500;
          text-align: center;
          color: #0869fb;
          border: 1.2px solid #0869fb;
        }

        &.cr-modal-save {
          background: #0869fb;
          font-weight: 700;
          color: #ffffff;
        }
      }
    }
  }
}
