.sidebar-container {
  display: flex;
  margin-bottom: 30px;

  * {
    font-family: "Inter" !important;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    width: 100%;
  }

  .main-text {
    margin-bottom: 2px;
  }

  .circle {
    width: 1.875rem;
    height: 1.875rem;
    color: #767676;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px !important;
    border: #a3a3a3 solid 1px;
  }

  .dotted-container {
    display: flex;
    width: 1.875rem;
    justify-content: center;
    height: 100%;
  }

  .dotted {
    width: 1px;
    border-left: #a3a3a3 dashed 1px;
    height: 100%;
  }
  .main-text {
    font-size: 1rem;
    font-weight: 600;
    opacity: 0.5;
  }
  .sub-text {
    font-size: 0.65rem;
    color: #767676;
    opacity: 0.5;
  }
  .last {
    width: 1.875rem;
  }

  &.active-step {
    .circle {
      background-color: #0869fb;
      color: #fff;
      border: none;
    }
    .main-text {
      opacity: 1;
    }
    .sub-text {
      opacity: 1;
    }
  }
  &.completed-step {
    .circle {
      background-color: #24b68a;
      color: #fff;
      border: none;
    }
    .main-text {
      opacity: 1;
    }
    .sub-text {
      opacity: 1;
    }
  }
  &.loader-step {
    .circle {
      background-color: #fff;
      border: #a3a3a3 solid 1px;
    }
    .main-text {
      opacity: 1;
    }
    .sub-text {
      opacity: 1;
    }
  }
}
