.tabs{
    .tab-item{
        border-bottom: 2px solid transparent;
        cursor: pointer;
        padding: 5px 15px;
        margin-bottom: 10px;
        font-weight: 600;
        cursor:pointer;
        &.active{
            border-bottom-color: #0869fb;
            color: #0869fb;
        }
    }
}