.select-ad-group {
  font-family: Roboto;
  font-size: 14px;
  line-height: 24px;
  color: #7a7a7a;
}
.create-ad-radio {
  display: grid;
  .radio-container {
    display: flex;
    margin-bottom: 0.5rem;
  }
  .radio-label {
    color: #7a7a7a;
  }
}
