.tableWrap {
  height: calc(100% - 58px);
  overflow: auto;
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th {
    padding: 16px;
    padding-left: 15px;
    text-align: left;
  }
}

.search-text {
  width: 95%;

  .right-panel {
    display: flex;

    .download-icon {
      position: relative;
    }
  }
}

.dropdown.customise-column.hoverable {
  margin: 0 10px !important;
}

.dropdown-recommendation.disabled {
  padding-left: 10px;
}

.dropdown.dropdown-download-parent.hoverable:hover {
  background: none !important;
}

.dropdown-download-parent {
  margin: 0 !important;

  &:hover {
    background: none !important;
  }

  .dropdown-download {
    background: none !important;
    width: 34px;
    padding: 10px !important;

    &:hover {
      background: none !important;
    }
  
    &:before {
      display: none !important;
    }
  }

  &.dropdown.hoverable .dropdown-menu {
    width: 180px !important;
    padding: 2px 0;
    a {
      font-size: 12px !important;
      border-bottom: none !important;
    }
  }
}

.btn-reject {
  font-size: 10px !important;
}

.dropdown > button.search-dropdown {
  width: 400px;
  padding: 0 !important;
  background: none !important;

  & > svg {
    width: 14px;
    height: 20px;
  }

  & > input {
    padding: 10px 25px;
  }
}

.position-set {
  margin: 0 !important;
  
  & > button {
    overflow-x: scroll;
  }

  .dropdown-menu {
    width: 40% !important;
    padding: 0 !important;
    border-radius: 10px;
  }

  .conf-dropdown {
    width: 90% !important;

    .conf-view {
      padding: 0 !important;

      .card-body {
        padding: 0;

        .card-title {
          font-size: 12px;
          font-weight: 600;
          background: #D9DAD9;
          padding: 5px 10px;
        }

        .conf-body {
          padding: 15px 20px 0;
          display: flex;
          flex-direction: column;
          
          .radio {
            display: flex;
            justify-content: space-between;

            label {
              margin-bottom: 5px;

              & > span {
                font-size: 10px;
                vertical-align: top;
                padding-left: 2px;
              }
            }
          }

          input {
            padding: 0 5px;
            flex: 1;
            font-size: 10px !important;
          }

          .input-container {
            display: flex;
            justify-content: space-between;

            span {
              padding: 0 10px;
            }
          }
        }

        .conf-footer {
          display: flex;
          justify-content: flex-end;
          padding: 5px 10px;

          & > button {
            font-size: 10px !important;
          }

          .btn-cancel {
            background-color: #F2F2F2;
            color: #000;
          }
        }
      }
    }
  }
}

.dropdown.hoverable.position-set {
  border: 1px solid #DDDDDD;
  border-radius: 10px;

  &:hover {
    background: none !important;
    border: 1px solid #DDDDDD !important;
  }

  .form-control {
    border: none;
  }

  .dropdown-menu a {
    font-size: 12px !important;
  }
}

.action_recommendations .table thead th {
  vertical-align: top;
}

.customize-button
{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 11.5px 20px
}