.loader-main-container {
  * {
    font-family: "Inter";
  }
  .loader-main-container-left {
    border-right: 2px solid #e1e1e1;
  }
}

.disable-continue{
  width: 90px !important;
  height: 32px !important;
  background: #0869FB !important;
  opacity: 0.5 !important;
  border-radius: 5.91744px !important;
  color: white !important;
  padding: 10.3555px 14.7936px !important;
}

.enable-continue{
  width: 90px !important;
  height: 32px !important;
  background: #0869FB !important;
  border-radius: 5.91744px !important;
  color: white !important;
  padding: 10.3555px 14.7936px !important;
}