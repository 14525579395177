.header-container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #EFF1FC;
  .date-range-picker {
    position: relative;
    padding-top: 0;
  }
  .performance-calendar {
    position: absolute;
    z-index: 1031;
    right: 10px;
    top: 70px;
    padding: 10px;
    .rdrStaticRange:nth-last-of-type(1) {
      display: none;
    }
    .rdrInputRange:nth-last-of-type(1) {
      display: none;
    }
  }
  .phase-container {
    display: flex;
    .btn-relative {
      position: relative;
      padding: 0;
    }
    .radio-dropdown-btn {
      top: 0em;
      &:hover {
        top: 0em;
      }
    }
  }
}
