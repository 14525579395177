.font-body {
  font-size: 1rem;
  font-weight: 400;
  * {
    font-family: "Inter" !important ;
    color: #333;
  }
  .btn {
    padding: 0.5rem 2rem !important;
    font-size: 1rem;
    border-radius: 0.5rem;
  }
}

.font-body .size-14 {
  font-size: 0.875rem;
}

.checkbox-strip {
  background: #ffffff !important;
  border: 1px solid #d6e2e9 !important;
  padding: 0.5rem;
  * {
    color: #7a7a7a !important;
  }
}
.checkbox-strip.selected {
  background: #f4f9ff !important;
  border: 1px solid #0869fb !important;
  * {
    color: #0869fb !important;
  }
}

.publish-modal-header {
  font-family: "Lato";
}

.weight-500 {
  font-weight: 500;
}

.text-scorpion {
  * {
    color: #5b5b5b !important;
  }
}

.text-dove-gray {
  color: #666 !important;
  * {
    color: #666 !important;
  }
}

.text-black {
  * {
    color: #000 !important;
  }
}

.text-white {
  * {
    color: #fff !important;
  }
}
.font-lato {
  * {
    font-family: "Lato" !important ;
    color: #333;
  }
}

.pad-0 {
  * {
    padding: 0px !important;
  }
}

.tooltip-top {
  * {
    color: #333 !important;
  }
  .text-white {
    color: #fff !important;
  }
  .tooltip {
    top: -150%;
    left: -30%;
  }
}
