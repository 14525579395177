.date-picker {
  .date-picker-dropdown {
    width: 250px;
  }

  .date-picker-footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px 5px;

    & > button {
      font-size: 10px !important;
    }

    .btn-cancel {
      background-color: #f2f2f2;
      color: #000;
    }
  }

  .dropdown-menu {
    width: 375px !important;
    max-height: 300px;

    .dropdown-item {
      padding: 0 20px;
    }
  }
}

.dropdown > .date-picker-dropdown {
  width: 210px;
  padding: 10px 12px 10px 20px !important;
  & > div {
    width: 90%;
  }
}

.dropdown.hoverable.date-picker .dropdown-menu a:hover {
  background: none !important;
}
