.image-video-card-container {
    width: 100%;

    .image-video-content {
        // border:1px solid black;
        width: 25%;
        margin: 10px;
        padding: 0;


    }

    .dropdown .dropdown-toggle {
        width: 100%;
        padding: 2px 5px !important;
        border: 1px solid red !important;
        height: 30px
    }

    .image-video-card-file {
        width: 100%;
        height: 155.66px;
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.2);
        cursor: default;
    }

    .image-video-content-div {
        position: relative;
    }

    .image-video-content-dropdown {
        position: absolute;
        top: 2px !important;
        right: 10px !important;
        z-index: 1;
    }

    .image-video-content-dropdown-modal-open {
        position: absolute;
        top: 2px !important;
        right: 10px !important;
        z-index: none;
    }

    .image-video-content-dropdown-toggle {
        background-color: transparent !important;
        width: 20px;
        float: right
    }

    .image-video-content-dropdown-toggle:hover {
        background-color: transparent !important;
    }

    .image-video-content-head {
        display: flex !important;
        ;
        justify-content: space-between !important;
    }

    .image-video-para {
        font-weight: 500;
        font-size: small;
        line-height: 24px;
        overflow: hidden;
        max-width: 75ch;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .video-play-btn {
        position: absolute;
        width: 49.34px;
        height: 32.93px;
        left: 8px;
        top: 113px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.7;
        border-radius: 4px;
        background-color: black;
        opacity: 0.7;
    }

    .image-video-content-head-dott {

        font-weight: bold;
        z-index: 9999999;
    }

    .image-video-content-head-dot-modal-view {

        font-weight: bold;
        z-index: none;
    }
}