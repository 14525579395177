$chip-y-spacing: 15px;
$chip-x-spacing: 5px;
$chip-button-width: $chip-y-spacing + $chip-x-spacing * 2;
$chip-border-radius: 15px;
$chip-background: #e6f0ff;
$chip-color: #2471f7;
$chip-min-height: 36px;

.chip {
  display: inline-block;
  margin-top: $chip-x-spacing;
  margin-bottom: $chip-x-spacing;
  margin-left: $chip-x-spacing;
  margin-right: $chip-button-width;
  position: relative;

  .chip-value {
    display: inline-block;
    padding: $chip-x-spacing;
    padding-left: $chip-y-spacing;
    padding-right: 5px;
    background: $chip-background;
    color: $chip-color;
    font-weight: bold;
    border-radius: $chip-border-radius 0 0 $chip-border-radius;
  }

  .chip-delete-button {
    background: $chip-background;
    color: $chip-color;
    border: 0;
    border-radius: 0 $chip-border-radius $chip-border-radius 0;
    padding: 5px 12px;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -$chip-button-width;
    line-height: 0.5;
    font-weight: bold;
  }
}
