.budget-planner-calendar {
  margin: 1em !important;
  border-radius: 0.625em;
  .light-bg {
    background-color: rgba(8, 105, 251, 0.1);
  }
  .border-y {
    border: 2px solid #f0f0f0;
  }
  .vertical-text {
    flex: 0 0 3em;
    min-width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 9em;
    cursor: pointer;
    span {
      transform: rotate(-90deg);
      white-space: nowrap;
    }
  }
  .calendar-cell {
    height: 9em;
    max-width: 8.5em;
    width: 100%;
    vertical-align: text-top;
    &:hover {
      .holiday-tag {
        visibility: visible !important;
      }
    }
    .holiday-tag {
      font-weight: 500;
      font-size: 0.625em;
      line-height: 1.6em;
      background: #e1edff;
      border-radius: 4.88635px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      padding: 0 0.5em;
    }
    .editable-amount {
      border: none;
      border-bottom: 1px solid #bdbdbd;
      border-radius: 0;
      padding: 0 0 0.3em 0.8em;
      font-weight: 500;
      font-size: 0.875em !important;
      line-height: 1.0625em;
      color: #808080;
      max-width: 90%;
      background: transparent;
      margin-left: 12px;
    }
    .currency-prefix {
      position: absolute;
      color: #808080;
    }
  }
  .header-cell {
    width: 100%;
    padding: 0.8em 0;
    cursor: pointer;
  }
  .empty-cell {
    flex: 0 0 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: transparent;
    padding: 0.8em 0;
  }
  .budgetPlanner-progress-bar {
    margin: 0.3em;
    &:hover {
      border-radius: 4.88635px;
      .text-on-hover {
        visibility: visible !important;
      }
    }
  }
}
