.performance_analysis {
  width: 100%;
  // width: 1310px;
  // height: 474px;
  // margin-left: 67px;
  background: #ffffff;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 10px;
  // min-height: 6vh;

  .chartbox {
    width: 100%;
    border-radius: 10px;
    // height: 100%;
    min-height: 2vh;
    .axesTitle {
      font-size: 0.8rem;
    }

    .chartbox-container {
      height: 32vh;
      width: 70%;
      margin-left: 13%;
    }

    canvas {
      height: inherit !important;
    }
  }

  .performance_button {
    color: #aaaaaa;
    font-size: 0.8rem;
    width: max-content;
    padding: 0 20px;

    &.active_button {
      background-color: #e6f0ff;
      border-radius: 4px;
      color: #0869fb;
    }
  }

  .chart-tabs {
    display: flex;
  }

  .performance_button:hover {
    border-radius: 4px;
    background-color: #edf3fe !important;
  }
}
