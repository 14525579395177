#simple-tab-0 {
  text-transform: capitalize;
  font-size: 18px;
}
#simple-tab-1 {
  text-transform: capitalize;
  font-size: 18px;
}
* {
  font-family: "Inter";
}
.css-uy5y4h {
  margin: 0px !important;
}
.manageAgency {
  .font-inter {
    * {
      font-family: "Inter" !important;
    }
  }
  .font-lato {
    font-family: "Lato" !important;
  }
  .font-12 {
    font-size: "12px" !important;
  }
  .font-14 {
    font-size: "14px" !important;
  }
  .font-16 {
    font-size: "16px" !important;
  }
  .font-18 {
    font-size: "18px" !important;
  }
  .font-30 {
    font-size: "30px" !important;
  }
  .font-400 {
    font-weight: 400 !important;
  }
  .font-600 {
    font-weight: 600 !important;
  }
  .font-700 {
    font-weight: 700 !important;
  }
  .font-color-1 {
    color: #303030 !important;
  }
  .font-color-2 {
    color: #2e3044 !important;
  }
  .font-color-header {
    color: #1b1b1b !important;
  }
}
