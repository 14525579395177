.message-container {
  .carousel {
    height: 100%;
    .dot {
      width: 10px;
      height: 10px;
      background: #a6c8fa;
      border-radius: 50%;
      transition: all 0.5s ease;
      &.selected {
        background: #3685fc;
        transform: scale(1.4);
      }
    }
  }
}
