
.view-more {
    .view-more-title {
        font-family: Roboto;
        font-size: 14px !important;
        line-height: 24px;
        color: #0869fb !important;
        cursor: pointer;
    }
   
    .keywords {
        display: flex;
        white-space: break-spaces;
    }
    .keywords-popup {
        display: none;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        padding: 12px;
        background: white;
        position: absolute;
        width: 50%;
        color: #666666;
        z-index: 9;
        .keyword-popup-title {
            font-family: Inter;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #212121;
        }
    }
    &:hover .keywords-popup {
        display: block;
    }
}