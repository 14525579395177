.action_recommendations {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  // height: calc(100vh - 510px) !important;
  overflow: unset;

  .table-responsive {
    overflow: scroll;
    height: calc(100% - 3rem);
    padding-bottom: 2px;
  }

  .table-responsive-md {
    .no-data {
      height: 150px !important;
      min-height: 75px !important;
    }
  }

  .pyxis-table-container {
    height: calc(100% - 3.2rem);
  }
}

.all-action-recommendation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  padding-top: 10px;

  .search-text {
    width: unset !important;
  }
}

.all-action-recommendation-header {
  .date-picker .dropdown-menu {
    right: 0;
    left: auto;
  }
}

.action_recommendations::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}

.action_recommendations::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
}

.action_recommendations::-webkit-scrollbar-thumb {
  background-color: rgb(221, 220, 220);
  outline: 1px solid rgb(189, 199, 209);
  border-radius: 0.3rem;
}