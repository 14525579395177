.upload-wrap {
  margin: 3rem 5rem;
  .file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
    left: 0;
    top: 0;
  }
  .file-upload-button {
    padding: 4.5rem 6rem;
    background: #f2f2f2;
    border-radius: 1rem;
  }
  .file-uploaded-button {
    background: #f2f2f2;
    padding: 4rem 6rem 5rem;
    border-radius: 1rem;
    .file-name {
      color: #a4a4a4;
      margin-top: 2rem;
    }
    .update-button {
      padding: 2rem 0rem 2.5rem;
    }
    .success-msg {
      margin-bottom: 2rem;
    }
  }
  .file-container {
    background: #fdfdfd;
    border: 1px dashed #a3a3a3;
    border-radius: 12px;
  }
  .text {
    text-align: center;
    label {
      color: #45494f;
      padding: 6rem 0;
    }
  }
  .dashed-border {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='black' stroke-width='4' stroke-dasharray='14%2c 14' stroke-dashoffset='11' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 0.8rem;
  }
}
.border-radius-12 {
  border-radius: 1rem;
}
