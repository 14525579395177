.slider-container{
    width:100%
}
// .css-1aznpnh-MuiSlider-root{
//      color: linear-gradient(270deg, #FC0A06 0%, #A7E004 100%)!important;   
// }
.css-14pt78w-MuiSlider-rail{
color:#dddddd;
// height: 5px !important;;
}
.css-eg0mwd-MuiSlider-thumb{
    color:#FFFFFF;;
    box-shadow: 0px 0px 2px rgba(30, 21, 83, 0.2);
}
.css-1gv0vcd-MuiSlider-track{
    background:#0869FB!important;
}
.slider-para{
    font-size:14px;
    color:#152370;
}