.create-ad-modal {
  .modal-dialog {
    max-width: 35vw !important;
  }
}
.ad-group-header-container {
  display: flex;
  flex-direction: column;
  .ai-group-name {
    font-family: Roboto;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: #7a7a7a;
  }
}
.adgroup-name {
  margin-bottom: 1rem;
}
.ad-group-campaign {
  max-height: 30vh;
  overflow: scroll;
  .no-ad-group {
    display: flex;
    justify-content: center;
    font-size: 14px;
  }
}
.select-ad-container {
  position: relative;
  .select-ref-ad {
    padding: 18px 0px 10px;
    border-top: 1px solid #dee2e6;
    margin-bottom: 0px;
    display: block;
  }
  .select-ad-label {
    font-family: Roboto;
    font-size: 15px;
    line-height: 24px;
    color: #212121;
  }
  .select-ad-list {
    max-height: 20vh;
    overflow: scroll;
  }
  .create-ad-ads-radio {
    .radio-label {
      font-size: 0.9rem;
      color: #212121;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 26rem;
    }
    .radio-desc {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 26rem;
      color: #7a7a7a;
    }
    .tooltip-container {
      position: unset;
      .custom-tooltip-layout {
        width: 420px;
        top: 0px;
      }
    }
  }
}
.adgroup-selected-content {
  .keywords-popup {
    display: none;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 12px;
    background: white;
    position: absolute;
    width: 35%;
    color: #666666;
    z-index: 9;
  }
  &:hover .keywords-popup {
    display: block;
  }
}
