.sample-file {
  color: #0869fb;
  cursor: pointer;
  display: block;
  text-decoration: none;
}
.range-slider-wrapper {
  border: 1px solid #dadcde;
  border-radius: 1rem;
}
.cluster-size {
  .color-light {
    color: #666666;
  }
}
