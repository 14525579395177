/* .custom-react-toggle {
    background-color: #caddff;
} */
.tai-style{

.heading-color-disabled {
    opacity: 0.5;
}

.slider-input::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #04AA6D; /* Green background */
    cursor: pointer;   
}
}