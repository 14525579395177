.multiselect {
  position: relative;
  button {
    width: 100%;
    max-width: 200px;
    min-width: 180px;
    justify-content: space-between;
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 40%;
    padding-left: 10px;
    display: block;
    z-index: 11;
    .dropdown-item {
      &:hover {
        background: #fff !important;
      }
    }
  }
}