.side-bar-new {
  * {
    font-family: "Inter" !important;
  }
  .side-bar-heading {
    font-family: "Lato";
    font-size: 1.625rem;
    font-weight: 700;
  }
  .side-bar-user-name {
    font-size: 1.125rem !important;
    font-weight: 500;
  }
  .side-bar-user-email {
    font-size: 0.875rem !important;
    font-weight: 400;
    color: #7e818d;
  }
  .pointer-cursor {
    cursor: pointer;
  }
}
