.upload-file-container{
    width: 20%; 
    background-color: aqua;   

    .upload-file-content{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border: 1px solid #F2F2F2;
        background-color: #F2F2F2;
        border-radius: 4px;
        padding:10px
    }
    .file-upload-btn{
        border:none;  
        background-color: #F2F2F2;
        font-size:small;
        font-weight:600;
    }
}