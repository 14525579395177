.success-modal {
  .font-lato {
    font-family: "Lato" !important ;
    color: #333 !important;
  }
}
.success-modal.font-body {
  * {
    font-family: "Inter" !important ;
    color: #727272 !important;
  }
}
