.textarea-label-container {
    width: 100%;
    padding: 0.25rem 1.25rem 0.45rem;
    background: #fff;
    border: 0.0625rem solid #ebebeb;
    box-shadow: 0.3125rem 0.25rem 0.75rem rgb(0 0 0 / 3%);
    border-radius: 0.5rem;
    .textarea-label--textarea {
      border: 0;
      border-bottom: 1px solid #dddddd;
      width: 100%;
      font-family: Roboto;
      font-size: 16px;
      line-height: 24px;
      color: #666666;
      outline: none;
      resize: none;
      overflow: hidden;
    }
    .textarea-label--label {
      font-family: Roboto;
      font-size: 12px;
      line-height: 24px;
      color: #a1a1a1;
      text-transform: capitalize;
    }
    .textarea-label--validation {
      font-family: Roboto;
      font-size: 14px;
      line-height: 24px;
      color: #666666;
    }
    .limit-reached {
      font-weight: 500;
      color: #004489;
    }
  }
  