.btnUI {
  border: 0;
  padding: 10px 18px;
  margin: 0 10px 0 0;
  border-radius: 10px;
  font-size: 0.8rem;
  border: none;
  outline: none;
  box-shadow: none;
  &.primary {
    background: #0869fb;
    color: #fff;
    height: auto;
    &:hover {
      background: #2854ac;
      color: #fff;
    }
    &:active {
      background: #183c84;
    }
  }
  &.secondary {
    background: #f2f2f2;
    color: #242424;
    &:hover {
      background: #e8e4e4;
    }
    &:active {
      background: #e0dcdc;
    }
  }
  &.success {
    background: #80bc3c;
    color: #fff;
    &:hover {
      color: #fff;
      background: #608c2c;
    }
    &:active {
      background: #507424;
    }
  }
  &.light {
    background: #f2f2f2;
    color: #242424;
    font-weight: 600;
    padding: 12px 16px;
    font-size: 14px;
    line-height: 16px;
    border-radius: 10px;
    &:hover {
      background: #e8e4e4;
    }
    &:active {
      background: #e0dcdc;
    }
  }
}
