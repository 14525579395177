.multi-select-with-icon-container {
  .multi-select-with-icon-active {
    background-color: var(--defaultBlue);
    color: var(--defaultWhite);
    width: 100%;
    height: 49px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px;
    cursor: pointer;
  }
  .multi-select-with-icon-normal {
    background-color: var(--defaultWhite);
    color: var(--defaultBlack);
    border: 1px solid #d9def8 !important;
    width: 100%;
    height: 49px;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
  }
  .multi-select-with-icon-title {
    font-size: 14px;
    // font-family: "interRegu  lar";
  }
  .multi-select-circle-check {
    width: 18px;
    height: 18px;
    left: 266.68px;
    top: 18px;
    background: #ffffff;
    border: 0.7px solid #8090e7;
    box-sizing: border-box;
    border-radius: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .check {
      color: var(--defaultBlue);
      font-size: 10px;
    }
  }
  .multi-select-circle {
    width: 16px;
    height: 16px;
    left: 266.68px;
    top: 18px;
    background: #ffffff;
    border: 0.7px solid #8090e7;
    box-sizing: border-box;
    border-radius: 50%;
  }
}
