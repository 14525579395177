.gsai-playground-variant-preview {
  height: 100%;
  .gsai-playground-preview--header {
    display: flex;
    justify-content: space-between;
    margin: 0.8rem 0rem;
    .perview {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: #333333;
    }
  }
  .gsai-playground-preview--body {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .input-label-container {
      padding: 0px;
      input {
        background: #f5faff;
        font-size: 0.8rem;
        margin-bottom: 0.8rem;
      }
    }
    .gsai-playground-input-container {
      display: flex;
      .input-separator {
        color: #333333;
        margin: 0px 4px;
      }
      .input-label-container {
        width: 4rem;
        .gsai-playground-display-url-path {
          max-width: 4rem;
          margin-bottom: 0.2rem;
          .input-label--validation {
            font-size: 0.8rem;
          }
        }
      }
      .gsai-playground-display-url {
        font-family: Roboto;
        font-size: 0.8rem;
        color: #666666;
        display: flex;
        align-items: center;
        height: 1.7rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 10rem;
        display: block;
        padding-top: 0.3rem;
      }
    }

    .gsai-playground-selection--heading {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 1.5rem;
      color: #333333;
    }
    .gsai-playground-preview-container {
      background: #ffffff;
      box-shadow: 0rem 0rem 0.75rem rgba(0, 0, 0, 0.15);
      border-radius: 0.625rem;
      padding: 0.6rem;
      .preview--header {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
      .preview--header--title {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 0.8rem;
        font-weight: 900;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;
        .preview--header--container {
          display: flex;
          align-items: center;
        }
        svg {
          margin: 0.375rem;
        }
        b {
          font-family: Roboto;
          font-weight: 900;
        }
        span {
          color: #333333;
          display: block;
          max-width: 17vw;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      .preview--header--heading {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.4rem;
        color: #3975ea;
        margin-bottom: 1rem;
        margin-left: 0.5rem;
      }
      .preview--header--desc {
        font-family: Roboto;
        font-size: 0.8rem;
        line-height: 1.2rem;
        color: #7a7a7a;
        margin-bottom: 0.75rem;
        margin-left: 0.5rem;
      }
    }
    .preview-description {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.2rem;
      a {
        text-decoration: none;
      }
    }
  }
}
