.metric_container {
  display: flex;
  flex-wrap: wrap;

  .error{
    font-size: small;
    color: red;
  }
  .metric_column {
    position: relative;
    max-width: 270px;
    display: flex;
    margin: 10px 10px 10px 0;
    justify-content: space-around;

    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    .metric_type {
      button {
        max-width: 120px;
      }

      .dropdown_arrow {
        width: 4px;

        svg {
          width: 8px;
        }
      }
    }

    .dropdown-menu {
      width: 220px !important;
    }
    .metric-tooltip {
      position: unset;
      .custom-tooltip-layout {
        padding: 0px 5px;
        width: fit-content;
      }
    }

    .metric_weight {
      .rt-input-input {
        position: relative;
        display: inline-block;

        input {
          background: #e9e9e9;
          padding: 9px 20px 10px 10px;
          border-radius: 6px;
          width: 70px;

          &:focus {
            background: #e9e9e9;
          }
        }

        &:after {
          content: "%";
          font-family: sans-serif;
          width: 12px;
          height: 12px;
          position: absolute;
          top: 45%;
          right: 5px;
          font-size: 12px;
          transform: translateY(-50%);
        }
      }
    }

    .delete-container {
      position: absolute;
      right: -7px;
      top: -11px;
      cursor: pointer;
    }

    .metric_type,
    .metric_weight {
      padding: 15px 10px;

      h5 {
        font-size: 12px;
      }
    }
  }

  .btn-add-plus {
    width: 27px;
    height: 27px;
    border-radius: 24px;
    font-size: 22px;
    margin: auto 10px;

    &:focus {
      box-shadow: none !important;
    }
  }
}
