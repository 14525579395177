.checkbox {
  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  label {
    // position: relative;
    cursor: pointer;
    margin: 0;
    &:before {
      content: "";
      -webkit-appearance: none;
      background-color: #fff;
      border: 1px solid #8a8a8a;
      border-radius: 3px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 9px;
      display: inline-block;
      //   position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 5px;
    }
  }
  input:checked + label:before {
    border: 1px solid #0869fb;
    position: relative;
  }
  input:disabled + label:before {
    cursor: not-allowed;
  }
  input:checked + label {
    position: relative;
  }
  input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 8px;
    width: 5px;
    height: 10px;
    border: solid #0869fb;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
