.google-table {
  width: 100% !important;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
  .action {
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background: #e5e5e5;
    }
  }
  th,
  td {
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
    max-width: 200px;
    width: auto;
    // overflow:hidden;
    text-overflow:ellipsis;
    // &:first-child {
    //   border-right: 0 !important;
    //   border-bottom: 0 !important;
    // }
    // &:nth-child(2) {
    //   border-left: 0 !important;
    // }
  }
  thead {
    font-size: 12px;
    width: 100%;
    background-color: #eeeeee;
    border-radius: 10px;
    th {
      color: #262626;
      padding: 10px;
    }
  }
  tbody {
    tr {
      td {
        padding: 10px;
        font-size: small;
        & > span {
          color: #212121;
          width: 100%;
          display: block;
          &:last-child {
            color: #7a7a7a;
            font-size: 10px;
          }
        }
      }
      &:last-child {
        border-bottom: 1px solid #dddddd !important;
      }
    }
  }
}

.table-head-tooltip 
{
  justify-content: space-between;
  min-width: 80px;
  display: flex;
  flex-direction: row;
  align-items:center;
}

.table-head-checkbox
{
 width: 10px;
}
