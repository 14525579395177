.smb-edit-brand {
  * {
    font-family: "Inter";
    font-size: 14px;
  }
}
.icon-based-data {
  .MuiOutlinedInput-input {
    .attribute-metric-icon {
      display: none;
    }
  }
    
}