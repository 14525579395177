.callibration-collapsed {
  min-width: 100%;
  background: white;
  border-radius: 0.625rem;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  display: flex;
  flex-direction: column;

  * {
    font-family: "Inter";
  }
  hr {
    margin: 0px;
    width: 100%;
    opacity: 0.1;
    // border-top: #00000030;

    // color: #efefef;
    // position: absolute;
  }
  .collapsed-header {
    padding: 0.75rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    font-weight: 600;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    .header-icon {
      margin-left: auto;
    }
    .collapsed-heading {
      display: flex;
      align-items: center;
    }
  }
  .collapsed-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    font-size: 0.875rem;
    padding: 0.75rem;

    * {
      font-family: "Lato";
      font-weight: 400;
    }
    .collapsed-strip {
      margin-top: 0.5rem;
      width: 100%;
      border: 1px solid #00000030;
      border-radius: 0.625rem;

      * {
        padding: 0.3rem;
        display: flex;
        align-items: center;
      }
      .strip-full-width {
        display: block;
      }
      &.strip-column {
        display: flex;
        width: 100%;
        * {
          //   flex-direction: column;
          width: 50%;
        }
      }
      .strip-half {
        display: flex;
        .strip-progress {
          margin-right: 0.6rem;
          //   justify-content: end;
          margin-left: auto;
        }
      }
      .strip-progress {
        font-weight: 800;

        * {
          font-weight: 800;
          padding: 0px;
        }
        .strip-value {
          margin-right: 0.75rem;
        }
        margin-left: auto;
        display: flex;
        align-items: center;
      }
      .collapsed-border-bottom {
        border-bottom: 1px solid #00000030;
      }
    }
  }

  .half-opacity {
    opacity: 0.5;
  }
}

#popover-basic-setup {
  border: 1px solid #5e9efe !important;
  box-shadow: 0px 8px 12px rgba(0, 31, 79, 0.08);
  min-width: 15rem;
  .popover-arrow::before {
    border-right-color: #5e9efe;
  }
  .custom-popover-header {
    font-family: "Inter";
    font-weight: 600;
    background: white;
    border: 0px;
    padding: 0.75rem 1rem;
    display: flex;
    .popover-header-close {
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.7;
      svg {
        height: 80%;
        width: 80%;
        cursor: pointer;
      }
    }
  }
  .popover-body {
    padding: 0 0.75rem 0.75rem 0.75rem;
  }
  .custom-popover-body {
    border-radius: 10px;
    background: #e3eeff;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    // justify-content: space-between;
    * {
      font-family: "Inter";
    }
    div {
      margin: 0.25rem;
      font-size: 0.75rem;
      background-color: #fff;
      padding: 0.5rem;
      border-radius: 10px;
    }
  }
}
