.connect-adaccount-message-container {
  .white-bg {
    background-color: #fff;
  }

  .w-30 {
    width: 30%;
  }

  .form-group {
    .input-search {
      border: 1px solid #dddddd;
      border-radius: 0.6rem;
      padding: 0.6rem 0.6rem 0.6rem 2.5rem;
    }

    .search-icon {
      left: 3%;
      top: 30%;
    }
  }

  .color-grey {
    opacity: 0.5;
  }
}

.message-icon-bg {
  background: #f5f5f5;
  border-radius: 8px;
}

.modal-button {
  border: 0;
  padding: 4px 34px !important;
  margin: 0 10px 0 0;
  border-radius: 0.5rem;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 24px;
  border: none;
  outline: none;
  box-shadow: none !important;

  &.primary {
    background: #0869fb;
    color: #fff;
    height: auto;

    &:hover {
      background: #2854ac;
      color: #fff;
    }

    &:active {
      background: #183c84;
    }
  }
}

.tms-select-account {
  width: 520px;
  background: #fff;
  position: relative;
  padding: 20px;
  border-radius: 8px;

  .header-logo {
    padding-top: 10px;
    padding-left: 17.5px;
  }

  .header-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 24px;

    .brand-name {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #313131;
      margin: 0;
      margin-bottom: 10px;
    }

    .selected-msg {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #000000;
      margin: 0;
    }
  }

  .form-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 27.5px;
    padding-bottom: 11px;
    padding-top: 24px;

    .choose-account {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #000000;
    }

    .input-search {
      width: 100%;
      position: relative;
      margin-top: 8px;
      margin-bottom: 16px;

      input {
        width: 100%;
        border: 1px solid #dddddd;
        border-radius: 0.6rem;
        padding: 2px 2px 2px 40px;
        height: 36px;
        font-size: 16px;
      }

      svg {
        position: absolute;
        left: 12.75px;
        top: 50%;
        transform: translateY(-50%);
        width: 18.75px;
        height: 18.75px;
      }
    }
  }

  .form-content {
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    padding-left: 27.5px;
    padding-top: 18px;
    padding-bottom: 25px;
    padding-right: 22.86px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    max-height: 400px;
    overflow-y: scroll;

    .label-component {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .label-title {
        .acccount-name {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #000000;
        }

        .acccount-id {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          text-align: center;
          color: #000000;
        }
      }

      .connected-msg {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 24px;
        text-align: center;
        color: #000000;

        &.opacity {
          opacity: 0.5;
        }
      }
    }
  }
}

.pixis-checkbox-container {
  user-select: none;
  width: 100%;

  &.disabled-cls {
    opacity: 0.5;
  }

  &.override-disable-styles {
    opacity: 1;
  }

  input {
    display: none;
  }

  label.pixis-checkbox-label {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    cursor: pointer;

    .pixis-checkbox {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 1.3219px solid #3975e9;
      height: 15px;
      width: 15px;
      border-radius: 4px;

      .check-icon {
        display: none;
      }

      .intermediate-line {
        display: none;
        width: 5px;
        background: #3975e9;
        height: 1px;

        &.intermediate {
          display: block;
        }
      }
    }

    .label-content {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #000000;
    }
  }

  &.intermediate {
    .intermediate-line {
      display: block;
    }
  }

  input:checked + .pixis-checkbox-label {
    .check-icon {
      display: block;
    }

    .intermediate-line {
      display: none !important;
    }
  }

  input:disabled + .pixis-checkbox-label {
    cursor: not-allowed;
  }
}

.confirm-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.footer-connect-acc-link {
  font-size: 12px;
  line-height: 15px;
}

.success-buttons {
  font-size: 12px !important;
  line-height: 24px;
  border: 0;
  padding: 4px 22px !important;
  margin: 0 10px 0 0;
  border-radius: 10px;
  border: none;
  outline: none;
  box-shadow: none !important;

  &.primary {
    background: #0869fb;
    color: #fff;
  }

  &.primary-reverse {
    background: #fff;
    color: #0869fb;
    border: 1px solid;
  }
}

.tms-no-adaccount {
  width: 520px;
  background: #fff;
  position: relative;
  padding: 20px;
  border-radius: 8px;

  .tms-no-adaccount-email {
    color: #0869fb;
  }

  .text-medium {
    font-size: 18px;
    line-height: 22px;

    .color-blue {
      color: #0869fb;
    }
  }

  .font-600 {
    font-weight: 600;
  }

  .font-400 {
    font-weight: 400;
  }

  .width-80 {
    width: 80%;
    margin: auto;
  }
}

.tms-loader-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 999999999999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
}

.skip-link {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #0869fb;
  cursor: pointer;
}
