.insights {
  .font-inter {
    * {
      font-family: "Inter" !important;
    }
  }
  .font-lato {
    font-family: "Lato" !important;
  }
  .font-12 {
    font-size: "12px" !important;
  }
  .font-14 {
    font-size: "14px" !important;
  }
  .font-16 {
    font-size: "16px" !important;
  }
  .font-30 {
    font-size: "30px" !important;
  }
  .font-400 {
    font-weight: 400;
  }
  .font-600 {
    font-weight: 600;
  }
  .font-700 {
    font-weight: 700;
  }

  /* Set the height of the scrolling element */
  .scroll {
    position: relative;
    z-index: 0;
    transform: rotate(21deg);
    height: 100%;
    width: 100%;
  }

  .scroll .scroll-item {
    position: absolute;
    z-index: 0;
    left: 0;
    animation: scroll 20s linear infinite;
  }

  .scroll .scroll-item2 {
    position: absolute;
    z-index: 0;
    left: 300px;
    animation: scroll2 20s linear infinite;
  }

  @keyframes scroll {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-1000px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  @keyframes scroll2 {
    0% {
      transform: translateY(-1000px);
    }
    50% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-1000px);
    }
  }
}

@media only screen and (max-width: 1400px) {
  .insights-right-box {
    padding-left: 0 !important;
    margin-left: -50px !important;
    width: 56% !important;
  }
  .scroll {
    left: 100px !important;
  }
  .scroll-item2 {
    left: 250px !important;
  }
}

@media only screen and (max-height: 700px) {
  .insights-left-box-content {
    padding: 2.5rem !important;
  }
  .insights-left-box-subsection {
    padding-top: 15px !important;
  }
  .insights-left-box-cta {
    padding-top: 20px !important;
  }
}

.dropdown.hoverable .dropdown-menu a {
  font-family: Roboto;
  font-size: 13px !important;
  line-height: 24px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown.hoverable .dropdown-menu a:hover {
  background: white !important;
}

.dropdown-row {
  margin-bottom: 7px;
  margin-right: 10px;
  .radio-label {
    .account-name {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 1em;
      line-height: 1.75em;
      white-space: nowrap;
      overflow: hidden;
      max-width: 14rem;
      text-overflow: ellipsis;
    }
    .account-id {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 0.75em;
      line-height: 1.25em;
      color: #666666;
    }
  }
}
