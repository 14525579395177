.create-ad-preview {
  height: 94%;
  .create-ad-preview--header {
    display: flex;
    justify-content: space-between;
    margin: 24px 0px;
    .perview {
      font-family: Roboto;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #333333;
    }
  }
  .create-ad-preview--body {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .create-ad-preview-container {
      background: #ffffff;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      padding: 24px;
      .preview--header {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
      .preview--header--title {
        margin-bottom: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg {
          margin: 6px;
        }
        b {
          font-family: Roboto;
          font-weight: 900;
        }
        span {
          color: #333333;
        }
      }
      .preview--header--heading {
        font-family: Roboto;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #3975ea;
        margin-bottom: 18px;
      }
      .preview--header--desc {
        font-family: Roboto;
        font-size: 16px;
        line-height: 24px;
        color: #7a7a7a;
        margin-bottom: 18px;
      }
    }
    .preview-description {
      font-family: Roboto;
      font-size: 16px;
      line-height: 24px;
      color: #333333;
      margin-top: 30px;
      a {
        text-decoration: none;
      }
    }
  }
}
