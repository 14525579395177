.list{
    display: table;
    .list-item{
        display: table-row;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        padding: 10px 25px;
        margin-bottom: 5px;
        margin-right: 5px;
        .information{
            background: #b2b2b2;
            width: 9px;
            height: 9px;
            color: #fff;
            border-radius: 5px;
            font-size: 7px;
            text-align: center;
            display: inline-block;
            margin-left: 5px;
        }
        .column{
            overflow: hidden;
            text-overflow: ellipsis;
            display: table-cell;
            min-height: 35px;
        }
        .status{
            color:#989898;
            font-size: 10px;
        }
        .recommendations{
            color:#63be09;
            font-size: 10px;
        }
    }
}