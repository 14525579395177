.create-ad-variant {
  .create-ad-selection--heading {
    padding: 24px 0px;
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    display: flex;
    align-items: center;
    position: relative;
    text-transform: capitalize;
    .create-ad--icon-container {
      svg {
        cursor: pointer;
      }
    }
    .create-ad--icon-container:hover .create-ad-heading--popup {
      display: block;
    }
    .create-ad-heading--popup {
      display: none;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      padding: 12px;
      background: white;
      position: absolute;
      top: 50px;
      right: 0px;
      width: 90%;
      color: #666666;
      color: #a9a9aa;
      font-size: 0.8rem;
      z-index: 1;
    }
  }
  .selection-container {
    margin-bottom: 24px;
  }
  .icon-container {
    width: 20px;
  }
  .create-ad--pin-icon {
    width: 11px;
  }
  .create-ad--delete-icon,
  .create-ad--pin-icon,
  .create-ad--lock-icon {
    opacity: 0;
    cursor: pointer;
  }
  .selection-container:hover {
    .create-ad--delete-icon,
    .create-ad--pin-icon,
    .create-ad--lock-icon {
      opacity: 1;
    }
  }
  .create-ad-selection--body {
    display: flex;
    flex-direction: column;
    .replace-section {
      &.inactive {
        opacity: 0.5;
        pointer-events: none;
      }
      .days {
        -moz-appearance: textfield;
        width: 30px;
        background-color: #fff;
        border: 1px solid #8a8a8a;
        border-radius: 3px;
        box-shadow: 0 1px 2px rgb(0 0 0 / 5%),
          inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }

    .links--container {
      display: flex;
      flex-direction: row;
    }
    a.link {
      font-size: 12px;
      &.inactive {
        color: #a7a7a7;
        pointer-events: none;
      }
    }
  }
  .create-ad-selection--footer {
    display: flex;
    justify-content: flex-end;
    .btn-generate-more {
      border-radius: 70px;
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
      color: #45494f;
      padding: 15px 25px;
    }
  }
  .create-ad--footer {
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
    margin-top: 24px;
    b {
      font-weight: 900;
      font-size: 14px;
      color: #333333;
    }
  }
  .selected-rows {
    .table {
      th,
      td {
        padding: 19px;
      }
    }
  }
}

.handle-gsai-error {
  background-color: #f6f6f6;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e0e0e0;

  h6 {
    padding-top: 15px;
    color: black;
    opacity: 0.7;
    margin-bottom: 6px;
  }
  p {
    color: black;
    opacity: 0.7;
    text-align: center;
    font-size: 15px;
    margin: 6px 10px 20px 10px;
  }

  button {
    background-color: transparent;
    padding: 0px;
    margin: 0px 0px 20px 0px;
  }
}

.create-variant-ads-container {
  overflow: hidden;
  display: flex;
  height: 100%;
  border-radius: 0.625rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
  .variant-info {
    width: 50%;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    position: relative;
  }
  .variant-header-svg {
    height: 1.375rem;
    width: 1.375rem;
  }
  .ads-container-header {
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  .container-header {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.625rem;
    color: #333333;
    margin-left: 0.875rem;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1600px) {
  .create-variant-ads-container {
    .ads-container-header {
      padding: 0.6rem;
    }
    .container-header {
      font-size: 1.05rem;
      line-height: 1.425rem;
      margin-left: 0.475rem;
      font-weight: 600;
    }
  }
}
