.journey {
  width: 100%;
  position: relative;
  * {
    font-family: "Inter";
  }

  .progress {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 20px;
  }

  .progress-chip {
    width: 20%;
    height: 10px;
    background-color: #e9ebee;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    border-right: 1px solid #d9d9d9;
  }

  .progress-chip:hover {
    border: 1px solid #d9d9d9;
  }
  .active-chip:hover {
    border: 1px solid #0869fb;
  }
}
