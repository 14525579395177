.profitability {
  background: #ffffff;
  border-radius: 10px;
  margin: 24px;

  hr {
    margin: 0 24px;
  }

  .campaign_sub_heading {
    font-size: 15px;
    margin-left: 20px;
    color: #666666;
    font-weight: 400;
  }

  .metric_sub_heading {
    font-size: 12px;
    padding-left: 24px;
    color: #666666;
    margin: 0;
  }

  .slider-rc {
    display: flex;
    margin: 0 24px;

    p {
      margin: 0;
      font-size: 11px;
      align-self: end;
    }

    .slider-container {
      width: fit-content;
      .slider-percent-label {
        display: flex;
        position: relative;
        padding-top: 15px;

        p {
          position: absolute;
        }
      }
    }
  }

  .profitability_roi_metric {
    padding: 10px 24px;

    .info-urgent {
      color: #f58a8a;
      font-size: 12px;
    }

    .btn-add-more {
      display: flex;
      justify-content: space-around;
      padding: 0;
      border-radius: 10px;
      font-size: 12px;
      width: 150px;
      height: 35px;

      .btn-plus {
        font-size: 24px;
      }
    }
  }
}
.campaign-footer {
  margin: 10px;
  display: flex;
  .color-fill {
    path {
      fill: #F5A623
    }
  }
  .btn-apply {
    text-align: center;
    background-color: #0869fb;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: small;
    width: 60px;
    height: 30px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
}
.result_metric_key {
  width: 80px;
  height: 30px;
  background-color: "black";
  border: 1px solid #ced4da;
  border-radius: 4px;
}
.view-btn {
  width: 20%;
  background-color: #e9e9e9;
  color: #313131;
}

.currency-symbol {
  width: 40px;
  height: 35px;
  background-color: white;
  padding: 0px;
  text-align: center;
}
.currency-input {
  display: flex;
}
.formData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.aiGroupSettingsForm {
  width: 96%;
  margin: 10px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  .formName {
    margin: 15px;
    color: #777777;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
  }
  .platformType {
    display: flex;
    justify-content: space-between;
  }
  .storyData {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 5px;
    margin-top: 20px;
    .budgetData {
      width: 30% !important;
      margin-right: 30px;
      .currency-input {
        width: 100%;
      }
    }
  }
  .adset-table {
    .text-md {
      padding: 12px;
      width: auto;
    }
  }
}
.selected-group-accordion {
  .accordion-button:not(.collapsed) {
    color: inherit;
    background: transparent;
  }
  .accordion-button::after {
    background-image: url("../../assets/svg/down.svg");
    background-size: 1rem;
  }
}
.disclaimer-warning-text {
  color: #1c1e21;
  font-weight: 400;
}
.optimisationEvents {
  .MuiInputBase-root {
    display: flex;
    #optimisation-events-dropdown {
      font-size: 0.8rem;
      padding:0.375rem 0.75rem;
    }
  }
}
