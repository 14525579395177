.step-wise-form-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.stepwise-form {
  .steps-data {
    .step {
      align-items: center;
      .step-number {
        cursor: pointer;
        .number {
          padding: 0.2rem 0.5rem;
          border-radius: 1rem;
          background: #edf3fe;
          color: #0869fb;
        }
      }
      .step-name {
        margin-right: 7rem;
        cursor: pointer;
        padding-left: 0.6rem;
        font-family: Roboto;
        font-size: 15px;
        line-height: 19px;
      }
    }
    .active-step {
      .step-number {
        .number {
          background: #0869fb;
          color: #fff;
          border: 4px double;
        }
      }
      .step-name {
        color: #0869fb;
      }
    }
  }
}
.dash-lines {
  position: relative;
  margin-right: 1rem;
  &:after {
    content: "";
    border-bottom: 1px dashed #a3a3a3;
    width: 6rem;
    position: absolute;
    right: 0;
    top: 50%;
  }
}
.center-box {
  margin: auto;
  background: #f2f2f2;
  padding: 12rem 10rem;
}
.stepwise-submit {
  box-shadow: 0px -4px 4px rgb(0 0 0 / 8%);
  border-radius: 0px 0px 10px 10px;
}
