.data-list-container {
    width: 100%;
    display: flex;
    align-items: center;

    .data-list-input {
        width: 50%;
        border: none;
        cursor: pointer;
        outline: 0;
        display: flex;
        align-items: center;
    }

    .data-list {
        display: flex;
        align-items: center;
        border: 1px solid #DDDDDD;
        width: 100%;
        background-color: "red";
        background: "#FFFFFF";
        box-sizing: border-box;
        border-radius: 4px;
        padding: 8px;
    }

    .data-list-search-container {
        width: 65%;
        margin-bottom: 5px;
    }

    .data-list-chip {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 1px solid black;
        padding-left: 4px;
        background: #F3F3F3;
        border: 1px solid #DEDEDE;
        box-sizing: border-box;
        border-radius: 3.5px;
        margin-right: 10px;
        position: relative;
        margin-bottom: 5px;
        // width:25px;
    }
}