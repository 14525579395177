.date-range-picker-container {
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background: white;
  .select-date-container {
    display: flex;
    .select-date {
      margin-left: auto;
      width: 150px;
    }
  }
}
