.drawer-header-title {
  font-family: Roboto;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 16px;
}
.drawer-header-keyword-container {
  padding: 16px;
  border: 1px solid #dadce0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  margin-bottom: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #666666;
  display: flex;
  white-space: break-spaces;
  position: relative;
  .keyword-title {
    color: #959595;
  }
  .keywords {
    display: flex;
    white-space: break-spaces;
  }
  .view-more {
    font-family: Roboto;
    font-size: 14px;
    line-height: 24px;
    color: #0869fb;
    cursor: pointer;
  }
  .keywords-popup {
    display: none;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 12px;
    background: white;
    position: absolute;
    top: 50px;
    right: 20px;
    width: 85%;
    color: #666666;
    .keyword-popup-title {
      font-family: Inter;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #212121;
    }
  }
  .view-more:hover .keywords-popup {
    display: block;
  }
}
