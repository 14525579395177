.create-variant-tab-container {
  height: 100%;

  .variant-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .MuiTabs-root.create-ad-variant-tab {
    min-height: 1.25rem;
    button {
      min-height: auto;
      padding: 0.5rem 1rem 0.5rem;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1.5rem;
      letter-spacing: -0.01rem;
    }
    .Mui-selected {
      color: #0869fb;
    }
  }
  .MuiBox-root {
    height: 100%;
  }
  .ads-container-header {
    width: 100%;
  }
  .container-header-count {
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 1.1875rem;
    color: #303030;
    margin-left: 0.5rem;
  }
  .ad-container-body {
    width: 100%;
    height: calc(100% - 92px);
    overflow-y: scroll;

    &.tbp {
      height: 50% !important;
    }
  }
  .ad-container-body-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    padding: 2.5rem;
    .drag-and-drop-desc {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 1.375rem;
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .create-ad-variant {
    padding: 16px;
    overflow-y: scroll;
    .new-data-icon {
      margin: 1rem 0;
    }

    .create-ad-selection--heading {
      padding: 1.5rem 0rem;
      font-family: Roboto;
      font-size: 1rem;
      line-height: 1.5rem;
      color: #333333;
      display: flex;
      align-items: center;
      position: relative;
      text-transform: capitalize;
      .create-ad--icon-container {
        svg {
          cursor: pointer;
        }
      }
      .create-ad--icon-container:hover .create-ad-heading--popup {
        display: block;
      }
      .create-ad-heading--popup {
        display: none;
        border: 0.0625rem solid #e0e0e0;
        box-sizing: border-box;
        box-shadow: 0rem 0rem 0.75rem rgba(0, 0, 0, 0.08);
        border-radius: 0.625rem;
        padding: 0.75rem;
        background: white;
        position: absolute;
        top: 3.125rem;
        right: 0rem;
        width: 90%;
        color: #666666;
        color: #a9a9aa;
        font-size: 0.8rem;
        z-index: 1;
      }
    }
    .variant-selection-container {
      margin-bottom: 1rem;
      gap: 8px;
      &:hover {
        .icons-container {
          opacity: 1;
        }
      }

      .input-label-container {
        padding: 0.25rem 1.25rem 0.45rem;
        background: #ffffff;
        border: 0.0625rem solid #ebebeb;
        box-shadow: 0.3125rem 0.25rem 0.75rem rgba(0, 0, 0, 0.03);
        border-radius: 0.5rem;
        .input-label--input {
          font-family: "Inter";
          font-style: normal;
          font-size: 1rem;
          letter-spacing: -0.01rem;
          line-height: 1.9375rem;
          color: #666666;
        }
        .input-label--label {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 0.75rem;
          line-height: 0.9375rem;
          color: #a6a6a6;
          padding-top: 0.3rem;
          text-transform: capitalize;
        }
        .input-label--validation {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 0.75rem;
          line-height: 0.9375rem;
          color: #a6a6a6;
          padding-top: 0.3rem;
        }
      }
    }
    .icon-container {
      display: contents;
      opacity: 0;
    }
    .create-ad--pin-icon {
      width: 0.6875rem;
    }
    .create-ad--delete-icon,
    .create-ad--pin-icon,
    .create-ad--lock-icon,
    .create-ad--pin-icon-blue {
      opacity: 1;
      cursor: pointer;
    }
    .variant-selection-container:hover {
      .create-ad--delete-icon,
      .create-ad--pin-icon,
      .create-ad--lock-icon,
      .create-ad--pin-icon-blue {
        opacity: 1;
      }
    }
    .create-ad-selection--body {
      display: flex;
      flex-direction: column;
      .replace-section {
        &.inactive {
          opacity: 0.5;
          pointer-events: none;
        }
        .days {
          -moz-appearance: textfield;
          width: 1.875rem;
          background-color: #fff;
          border: 0.0625rem solid #8a8a8a;
          border-radius: 0.1875rem;
          box-shadow: 0 0.0625rem 0.125rem rgb(0 0 0 / 5%),
            inset 0rem -0.9375rem 0.625rem -0.75rem rgb(0 0 0 / 5%);
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }

      .links--container {
        display: flex;
        flex-direction: row;
      }
      a.link {
        font-size: 0.75rem;
        &.inactive {
          color: #a7a7a7;
          pointer-events: none;
        }
      }
    }
    .create-variant-selection--footer {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      bottom: 0;
      right: 0;
      background: #ffffff;
      box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
      border-radius: 0px 0px 0px 7px;
      width: 100%;
      padding: 0.6rem 0rem;
      .btn-generate-more {
        background: #e9f2ff;
        border-radius: 0.375rem;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1rem;
        padding: 0.75rem 1rem;
        color: #0869fb;
      }
    }
    .create-ad--footer {
      font-family: Roboto;
      font-size: 1rem;
      line-height: 1.5rem;
      color: #666666;
      margin-top: 1.5rem;
      b {
        font-weight: 900;
        font-size: 0.875rem;
        color: #333333;
      }
    }
    .selected-rows {
      .table {
        th,
        td {
          padding: 1.1875rem;
        }
      }
    }
  }

  .handle-gsai-error {
    background-color: #f6f6f6;
    margin-bottom: 1.5625rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
    border: 0.0625rem solid #e0e0e0;

    h6 {
      padding-top: 0.9375rem;
      color: black;
      opacity: 0.7;
      margin-bottom: 0.375rem;
    }
    p {
      color: black;
      opacity: 0.7;
      text-align: center;
      font-size: 0.9375rem;
      margin: 0.375rem 0.625rem 1.25rem 0.625rem;
    }

    button {
      background-color: transparent;
      padding: 0rem;
      margin: 0rem 0rem 1.25rem 0rem;
    }
  }
}
.create-variant-tab-container > div:nth-child(2) {
  height: 92%;
}

.create-variant-tab-container > div:nth-child(2) > div:nth-child(1) {
  height: 100%;
}
.create-variant-tab-container > div:nth-child(2) > div:nth-child(2) {
  height: 100%;
}
@media only screen and (min-width: 56.25rem) and (max-width: 100rem) {
  .create-variant-tab-container {
    .MuiTabs-root.create-ad-variant-tab {
      min-height: 1.25rem;
      button {
        font-weight: 700;
        font-size: 1.05rem;
        line-height: 1.2rem;
      }
      .Mui-selected {
        color: #0869fb;
      }
    }
  }
}

.drag-above {
  margin-top: 72px;
}
.metric-popup {
  box-shadow: 0px 1px 12px rgb(0 0 0 / 10%)
}
.metric-popover {
  .individual-item {
    color: #666666;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.9375em;
  }
  .border-bottom {
    border-bottom: 1px solid #F3F3F3;
  }
  .icon-fix-width {
    width: 16px;
  }
}
.to-be-published {
  height: calc(100% - 44px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.flex-1 {
  flex: 1;
}
.warning-for-publish {
  * {
    font-family: 'Inter';
  }
}