.searchable-select {
    cursor: pointer;
    .selected-value {
        border: 1px solid #dde1e3;
        border-radius: 0.6rem;

        .down-icon {
            min-width: 0.9375em;
        }
    }

    .background-white {
        background-color: #FFF !important;

        &:hover {
            background-color: #FFF !important;
        }
    }


    &.hoverable:hover {
        background-color: #FFF !important;
    }
}
.popover-action {
    height: 16.625em; 
    width: 20%;
    .MuiPopover-paper {
        width: 100%;
    }
}
.select-dropdown {
    z-index: 100;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
    border-radius: 5px;
    max-height: 230px;
    overflow: auto;

    label {
        cursor: pointer !important;

        &:hover {
            background-color: #f1f7ff;
        }

        .radio-label {
            .color-grey {
                color: #bebfc0;
            }
        }
    }

    .input-search {
        border: 1px solid #e9e9e9;
        border-radius: 0.6rem;
    }

    .search-icon {
        top: 25%;
        right: 5%;
    }
}